import styled from "styled-components";
import TeamBlock from "./TeamBlock/TeamBlock";
import EmptyList from "../EmptyList/EmptyList";
import { useEffect, useState } from "react";

/**
 * Styles
 */

const TeamListWrapper = styled.div `

    position:relative;
    display:flex;
    flex-direction:column;
    top:0;
    left:0;
    width:100%;
    border-collapse: collapse;
    overflow-y:auto;
    height:100%;
    border-spacing:0 10px;

    & > div {
        opacity: ${props => (props.isLoading ? 0 : 1)};
        transition: opacity 0.25s ease-in-out;
    }

`

/**
 * Component
 * @param {*} props 
 * @returns 
 */
const TeamList = (props) => {

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(props.isLoading);
    }, [props.isLoading])

    return (

        props.teams && props.deleteTeam && props.confirmTeam && props.handleDerivate &&

        <>
            
            {props.teams.length === 0 ? <EmptyList link={{path: '/add-team', text: "Inscrivez une première équipe"}} /> : null}
            
            <TeamListWrapper isLoading={isLoading}>
                { props.teams.map((team) => (
                    <TeamBlock key={team.team.teamId} team={team} onHover={() => props.onHoverElement(team.team.teamId)} deleteTeam={props.deleteTeam} confirmTeam={props.confirmTeam} handleDerivate={props.handleDerivate} />
                )) }
            </TeamListWrapper>

        </>

    );
    
}

export default TeamList;