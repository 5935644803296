import styled from "styled-components";
import { useEffect, useState } from "react";
import LevelList from "../../components/Software/LevelList/LevelList";
import { useLocation, useNavigate } from 'react-router-dom';
import { useCookies } from "react-cookie";
import fetchAPI from "../../utils/fetch";
import Wrapper from "../../components/Software/Wrapper/Wrapper";
import LargeButton from "../../components/Software/LargeButton/LargeButton";
import HeaderWrapper from "../../components/Software/Wrapper/HeaderWrapper/HeaderWrapper";

/**
 * Styles
 */

const Cols = styled.div `

    position:relative;
    top:0;
    left:0;
    width:100%;
    height:85%;
    display:flex;
    flex-direction:row;
    justify-content:space-between;

`

const Col1 = styled.div `

    position:relative;
    top:0;
    left:0;
    width:80%;

`

const Col2 = styled.div `

    position:relative;
    top:0;
    left:0;
    width:17%;
    display:flex;
    justify-content:start;
    align-items:start;
    flex-direction:column;
    overflow-y:auto;

`

/**
 * Component
 * @returns 
 */
const Levels = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const [cookies] = useCookies(['competition']);
    const [levels, setLevels] = useState([]);
    const [levelsRestriction, setLevelsRestriction] = useState(null);
    const [fetchTrigger, setFetchTriger] = useState(0);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if(location.state?.success) setSuccess(location.state.success);
    }, [location.state?.success])

    /**
     * Load levels from database
     */
    useEffect(() => {

        const fetchLevels = async () => {

            try {

                setIsLoading(true);

                const {data, error} = await fetchAPI("GET", "/level/getallofcompetition/pro", {
                    competitionId: parseInt(cookies.competition)
                });

                if(error) throw new Error();

                setLevels(data && data.levels ? data.levels : []);
                setIsLoading(false);

            }
            catch(e) {
                navigate('/e');
            }

        }

        fetchLevels();

    }, [cookies.competition, fetchTrigger, navigate]);

    // Handlers

    /**
     * Add btn handler
     * @param {*} e 
     */
    const handleRedirectToAddPage = (e) => {
        e.preventDefault();
        navigate("/add-level");
    }

    /**
     * Search handler
     * @param {*} e 
     */
    const handleSearch = (e) => {

        const search = e.target.value;

        if(search !== null && search.length !== 0) {

            setLevelsRestriction(levels.filter((l) => {
                return l.name.toLowerCase().includes(search.toLowerCase());
            }));

        } else {
            setLevelsRestriction(null);
        }

    }

    /**
     * Delete handler
     * @param {*} event 
     * @param {*} id 
     * @returns 
     */
    const handleDelete = async (event, id) => {

        event.stopPropagation();

        const cf = window.confirm("Souhaitez-vous vraiment supprimer le terrain ?");

        if(cf) {

            try {

                setIsLoading(true);

                const {error} = await fetchAPI('DELETE', '/level/delete/pro', {
                    levelId: id
                });

                if(error) {
                    if(error === "ERR_LINKED_TEAMS") throw new Error("Des équipes sont liées à ce niveau.");
                }

                setFetchTriger(fetchTrigger + 1);

            }
            catch(e) {

                setIsLoading(false);

                if(e.message.includes('équipes')) {
                    setError(e.message);
                    return;
                }

                navigate('/e');
                return;

            }

        }

    }

    return (

        levels &&

        <Wrapper isLoading={isLoading} popup={[error, success]}>

            <HeaderWrapper title="Gestion des niveaux" showSearchEngine={true} searchEnginePlaceholder="Rechercher des niveaux ..." placeholderOnChange={handleSearch} />

            <Cols>

                <Col1>
                    <LevelList levels={levelsRestriction === null ? levels : levelsRestriction} deleteLevel={handleDelete} />
                </Col1>

                <Col2 className={isLoading ? "loading_content" : "loaded_content"}>
                    <LargeButton message1="Ajouter" message2="un niveau" callback={handleRedirectToAddPage} />
                </Col2>

            </Cols>

        </Wrapper>

    );

}

export default Levels;