import Area from "../components/Software/Area/Area";
import Header from "../components/Software/Header/Header";

/**
 * Component
 * @returns 
 */
const Software = () => {

    return (
        <>
            <Header />
            <Area />
        </>
    );
}

export default Software;