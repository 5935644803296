import styled from "styled-components"
import LargeButton from "../LargeButton/LargeButton";

/**
 * Styles
 */

const ValidationSectionArea = styled.div `

    position:relative;
    top:0;
    left:0;
    width:100%;
    display:flex;
    flex-direction:row;
    margin:12px 0 10px 0;
    justify-content:end;

`

const ButtonContainer = styled.div `

    width:240px;
    height:80px;

    &.small {
        width:160px;
        height:50px;
    }

`

/**
 * Component
 * @param {*} props 
 * @returns 
 */
const ValidationSection = (props) => {

    return (

        <ValidationSectionArea className={props.className}>

            <ButtonContainer className={props.small && "small"}>
                <LargeButton icon={props.icon} message1={props.message1} message2={props.message2} callback={props.callback} small={props.small} renderButton={props.renderButton ?? 0} />
            </ButtonContainer>

        </ValidationSectionArea>

    );
    
} 

export default ValidationSection;