import styled from "styled-components";
import SearchResultElement from "./SearchResultElement/SearchResultElement";
import { useEffect } from "react";

const StyledDiv = styled.div `

    position:absolute;
    top:80px;
    display:flex;
    flex-direction:column;
    left:40px;
    width:800px;
    background:white;
    border-radius:10px;
    z-index:9999999;
    box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.17);

`

const SearchResults = (props) => {

    return (

        <StyledDiv>

            { props.results.map((r, i) => (
                <SearchResultElement key={i} odd={i % 2 === 0} elementName={r.elementName} elementType={r.elementType} elementId={r.elementId} handleNavigate={props.handleNavigate} />
            ))}

        </StyledDiv>

    );

}

export default SearchResults;