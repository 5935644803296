import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
// eslint-disable-next-line
import NetworkInfo from "./NetworkInfo/NetworkInfo";

/**
 * Styles
 */

const AreaWrapper = styled.div `

    position:absolute;
    top:100px;
    left:120px;
    margin:0 40px 40px 40px;
    width:calc(100% - 200px);
    height:calc(100% - 140px);
    overflow:none;
`

/**
 * Component
 * @returns 
 */
const Area = (props) => {

    console.log("Neoscore Web 3.0");

    // States
    // eslint-disable-next-line
    const [online, setOnline] = useState(true);

    // Handlers
    const handleOnline = () => setOnline(true);
    const handleOffline = () => setOnline(false);

    // Listen connection changes
    useEffect(() => {

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

    }, []);

    return (
        <AreaWrapper>
            <Outlet />
        </AreaWrapper>
    );

}

export default Area;