import styled from "styled-components";
import { useEffect, useState } from "react";
import TeamList from "../../components/Software/TeamList/TeamList";
import TeamInfo from "../../components/Software/TeamList/TeamInfo/TeamInfo";
import TeamResume from "../../components/Software/TeamList/TeamResume/TeamResume";
import { useLocation, useNavigate } from 'react-router-dom';
import { useCookies } from "react-cookie";
import fetchAPI from "../../utils/fetch";
import Wrapper from "../../components/Software/Wrapper/Wrapper";
import Popup from "../../components/Software/Popup/Popup";
import teamModelConverter from "../../utils/modelConverters/team";
import DerivationSection from "../../components/Software/DerivationSection/DerivationSection";
import Loader from "../../components/Loader/Loader";
import LargeButton from "../../components/Software/LargeButton/LargeButton";
import TextInput from "../../components/Software/Inputs/TextInput";
import FormBlock from "../../components/Software/FormBlock/FormBlock"
import CheckboxInput from "../../components/Software/Inputs/CheckboxInput"
import TitleWrapper from "../../components/Software/Wrapper/HeaderWrapper/TitleWrapper/TitleWrapper";

/**
 * Styles
 */

const Cols = styled.div `

    position:relative;
    top:0;
    left:0;
    width:100%;
    height:85%;
    display:flex;
    flex-direction:row;
    justify-content:space-between;

`

const Col1 = styled.div `

    position:relative;
    top:0;
    left:0;
    width:80%;

`

const Col2 = styled.div `

    position:relative;
    top:0;
    left:0;
    width:17%;
    display:flex;
    justify-content:start;
    align-items:start;
    flex-direction:column;
    overflow-y:auto;

`

const HeaderTitleWrapper = styled.div `

    position:relative;
    top:0;
    left:0;
    width:80%;
    display:flex;
    justify-content:space-between;
    align-items:center;

`

const SearchEngine = styled.div `

    width:500px;
    height:38px;
    
`

const OnlyNotPayedDiv = styled.div `
    width:32px;
    height:32px;
    margin:0 10px 0 20px;
`

/**
 * Component
 * @returns 
 */
const Teams = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const [cookies] = useCookies(['competition']);
    const [teams, setTeams] = useState([]);
    const [teamsRestriction, setTeamsRestriction] = useState(null);
    const [team, setTeam] = useState(teams[0] ? teams[0] : null);
    const [onlyNotPayed, setOnlyNotPayed] = useState(false);
    const [onlyNotConfirmed, setOnlyNotConfirmed] = useState(false);
    const [showDerivationBox, setShowDerivationBox] = useState(false);
    const [selectedDerivationTeam, setSelectedDerivationTeam] = useState(null);
    const [selectedDerivationTourId, setSelectedDerivationTourId] = useState(null);
    const [selectedDerivationPouleId, setSelectedDerivationPouleId] = useState(null);
    const [derivationTours, setDerivationTours] = useState([]);
    const [derivationPoules, setDerivationPoules] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [fetchTrigger, setFetchTriger] = useState(0);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    useEffect(() => {
        if(location.state?.success) setSuccess(location.state.success);
    }, [location.state?.success])
    
    /**
     * Load teams from database
     */
    useEffect(() => {

        const fetchTeams = async () => {

            try {

                setIsLoading(true);

                const {data, error} = await fetchAPI("GET", "/team/getallofcompetition/pro", {
                    competitionId: parseInt(cookies.competition)
                });

                if(error) throw new Error();

                setTeams(data && data.teams ? data.teams : []);
                setTeam(data && data.teams && data.teams[0] ? data.teams[0] : null);
                setIsLoading(false);

            }
            catch(e) {
                
                navigate('/e');
                return;

            }

        }

        fetchTeams();

    }, [cookies.competition, fetchTrigger, navigate]);

    /**
     * Change selected derivation tour id handler
     * @param {*} id 
     */
    const handleChangeSelectedDerivationTourId = async (id) => {

        const rem = selectedDerivationTourId === id ? true : false;
        setSelectedDerivationTourId(rem ? null : id);
        
        for(const t of derivationTours) {
            if(t.tourId === id) setDerivationPoules(t.poules);
        }

        if(rem) setDerivationPoules([]);

    }

    /**
     * Change selected deriation poule id handler
     * @param {*} id 
     */
    const handleChangeSelectedDerivationPouleId = async (id) => {

        const rem = selectedDerivationPouleId === id ? true : false;
        setSelectedDerivationPouleId(rem ? null : id);

    }

    /**
     * Close window of derivation handler
     * @param {*} e 
     * @param {*} team 
     */
    const handleCloseDerivation = async (e, team) => {

        setShowDerivationBox(false);
        setSelectedDerivationTeam(null);
        setDerivationTours([]);

    }

    /**
     * Validate derivation handler
     * @param {*} e 
     * @param {*} team 
     */
    const handleAskDerivation = async (e, team) => {

        e.stopPropagation();

        try {

            const {data, error} = await fetchAPI('GET', '/tour/getpossiblederivation/pro', {
                competitionId: cookies.competition,
                teamId: team.teamId
            });

            if(error) throw new Error();
            
            setShowDerivationBox(true);
            setDerivationTours(data.tours);
            setSelectedDerivationTeam(team);

        }
        catch(e) {
            
            navigate('/e');
            return;

        }

    }

    /**
     * Change selected team handler
     * @param {*} id 
     */
    const handleChangeSelectedTeam = (id) => {

        teams.forEach((t) => {
            if(t.team.teamId === id) setTeam(t);
        });

    }

    /**
     * Btn add team handler
     * @param {*} e 
     */
    const handleRedirectToAddPage = (e) => {
        e.preventDefault();
        navigate("/add-team");
    }

    /**
     * Search handler
     * @param {*} e 
     */
    const handleSearch = (e) => {

        setOnlyNotConfirmed(false);
        setOnlyNotPayed(false);

        const search = e.target.value;

        if(search !== null && search.length !== 0) {

            setTeamsRestriction(teams.filter((t) => {
                return t.team.name.toLowerCase().includes(search.toLowerCase());
            }));

        } else {
            setTeamsRestriction(null);
        }

    }

    /**
     * Delete team handler
     * @param {*} event 
     * @param {*} id 
     */
    const handleDelete = async (event, id) => {

        event.stopPropagation();

        const cf = window.confirm("Souhaitez-vous vraiment supprimer l'équipe ?");

        if(cf) {

            try {

                const {error} = await fetchAPI('DELETE', '/team/delete/pro', {
                    teamId: id
                });

                if(error) throw new Error();

                setError(null);
                setFetchTriger(fetchTrigger + 1);

            }
            catch(e) {
                
                navigate('/e');
                return;

            }

        }

    }

    /**
     * Handler for team confirmation
     * @param {*} event 
     * @param {*} id 
     * @param {*} confirm 
     */
    const handleConfirmTeam = async (event, id, confirm) => {

        event.stopPropagation();

        try {

            const {error} = await fetchAPI('PUT', '/team/switch/pro', {
                teamId: id,
                confirmed: confirm
            });

            if(error) throw new Error();
    
            setError(null);
            setFetchTriger(fetchTrigger + 1);

        }
        catch(e) {
            
            navigate('/e');
            return;

        }

    }

    /**
     * Handle select for only not payed teams
     */
    const handleChangeOnlyNotPayed = () => {
        if(onlyNotPayed) {
            setTeamsRestriction(null);
            setOnlyNotPayed(false);
        } else {
            setTeamsRestriction(teams.filter((t) => {
                return teamModelConverter.getFormattedPayment(t.team).className === "error";
            }));
            setOnlyNotPayed(true);
            setOnlyNotConfirmed(false);
        }
    }

    /**
     * Handle select for only not confirmed teams
     */
    const handleChangeOnlyNotConfirmed = () => {
        if(onlyNotConfirmed) {
            setTeamsRestriction(null);
            setOnlyNotConfirmed(false);
        } else {
            setTeamsRestriction(teams.filter((t) => {
                return teamModelConverter.getFormattedConfirmation(t.team).className === "error";
            }));
            setOnlyNotConfirmed(true);
            setOnlyNotPayed(false);
        }
    }

    /**
     * Handle for submission of derivation
     * @returns 
     */
    const handleSubmitDerivation = async () => {

        if(!selectedDerivationTourId || !setSelectedDerivationTeam) {
            setError("Toutes les informations n'ont pas été renseignées.");
            return;
        }

        setError(null);
        setShowDerivationBox(false);

        try {

            const {error} = await fetchAPI('PUT', '/tour/join/pro', {
                targetTourId: selectedDerivationTourId,
                joinMap: [
                    {
                        teamId: selectedDerivationTeam.teamId,
                        pouleId: selectedDerivationPouleId
                    }
                ]
            });

            if(error) throw new Error();

            navigate('/manager/' + selectedDerivationTourId);
            return;

        }
        catch(e) {

            navigate('/e');
            return;

        }


    }

    return (

        teams &&

        <Wrapper>

            <Loader isLoading={isLoading} />

            <Popup content={[error, success]} />

            { showDerivationBox && <DerivationSection selectedDerivationTourId={selectedDerivationTourId} handleChangeSelectedDerivationTourId={handleChangeSelectedDerivationTourId} tours={derivationTours} team={selectedDerivationTeam} handleCloseDerivate={handleCloseDerivation} poules={derivationPoules} selectedDerivationPouleId={selectedDerivationPouleId} handleChangeSelectedDerivationPouleId={handleChangeSelectedDerivationPouleId} handleSubmitDerivation={handleSubmitDerivation} />}

            <HeaderTitleWrapper>
                <TitleWrapper title="Liste des équipes" />
                <SearchEngine>
                    <TextInput placeholder="Rechercher des équipes ..." onChange={handleSearch} />
                </SearchEngine>
                <FormBlock>
                    <OnlyNotPayedDiv>
                        <CheckboxInput value={onlyNotPayed} onChange={handleChangeOnlyNotPayed} />
                    </OnlyNotPayedDiv>
                    <label>A régler</label>
                </FormBlock>
                <FormBlock>
                    <OnlyNotPayedDiv>
                        <CheckboxInput value={onlyNotConfirmed} onChange={handleChangeOnlyNotConfirmed} />
                    </OnlyNotPayedDiv>
                    <label>A confirmer</label>
                </FormBlock>
            </HeaderTitleWrapper>

            <Cols>

                <Col1>
                    <TeamList teams={teamsRestriction === null ? teams : teamsRestriction} onHoverElement={handleChangeSelectedTeam} deleteTeam={handleDelete} confirmTeam={handleConfirmTeam} handleDerivate={handleAskDerivation} isLoading={isLoading} />
                </Col1>

                <Col2 className={isLoading ? "loading_content" : "loaded_content"}>

                    <LargeButton message1="Ajouter" message2="une équipe" callback={handleRedirectToAddPage} />
                    { team && <TeamInfo team={team.team} /> }
                    <TeamResume teams={teams} />

                </Col2>

            </Cols>

        </Wrapper>

    );

}

export default Teams;