import styled from "styled-components";
import TextInput from "../../components/Software/Inputs/TextInput";
import { useEffect, useState } from "react";
import SelectInput from "../../components/Software/Inputs/SelectInput";
import { useNavigate, useParams } from "react-router-dom";
import Section from "../../components/Software/Section/Section";
import Wrapper from "../../components/Software/Wrapper/Wrapper";
import CheckboxInput from "../../components/Software/Inputs/CheckboxInput";
import FormBlock from "../../components/Software/FormBlock/FormBlock";
import ValidationSection from "../../components/Software/ValidationSection/ValidationSection";
import FormBlockCategory from "../../components/Software/FormBlockCategory/FormBlockCategory";
import TextareaInput from "../../components/Software/Inputs/TextareaInput";
import FileInput from "../../components/Software/Inputs/FileInput";
import competitionValidator from "../../utils/validators/competition";
import fetchAPI from "../../utils/fetch";
import moment from "moment";
import HeaderWrapper from "../../components/Software/Wrapper/HeaderWrapper/HeaderWrapper";

/**
 * Styles
 */

const CompetitionNameDiv = styled.div `
    width:300px;
    height:32px;
`

const CompetitionPlaceDiv = styled.div `
    width:200px;
    height:32px;
`

const CompetitionDateDiv = styled.div `
    width:100px;
    height:32px;
`

const CompetitionTimeDiv = styled.div `
    width:100px;
    height:32px;
`

const CompetitionSportDiv = styled.div `
    width:auto;
    height:32px;
`

const CompetitionPaliersDiv = styled.div `
    width:auto;
    height:32px;
`

const CompetitionAverageDiv = styled.div `
    width:auto;
    height:32px;
`

const CompetitionIndividualDiv = styled.div `
    width:32px;
    height:32px;
    margin:0 10px 0 0;
`

const CompetitionNumberTeamDiv = styled.div `
    width:32px;
    height:32px;
    display:inline-block;
    margin:0 10px;
`

const CompetitionPublishedDiv = styled.div `
    width:32px;
    height:32px;
    margin:0 10px 0 0;
`

const CompetitionPrivateDiv = styled.div `
    width:32px;
    height:32px;
    margin:0 10px 0 0;
`

const CompetitionPrivateMessageDiv = styled.div `
    width:400px;
    height:32px;
`
const CompetitionPrivateCodeDiv = styled.div `
    width:200px;
    height:32px;
`

const CompetitionOnlineRegistrationDiv = styled.div `
    width:32px;
    height:32px;
    margin:0 10px 0 0;
`

const CompetitionOnlineRegistrationClosingDiv = styled.div `
    width:32px;
    height:32px;
    margin:0 10px 0 0;
`

const CompetitionOnlineRegistrationClosingDateDiv = styled.div `
    width:100px;
    height:32px;
    margin:0 10px 0 0;
`

const CompetitionOnlinePaymentDiv = styled.div `
    width:32px;
    height:32px;
    margin:0 10px 0 0;
`

const CompetitionOnlinePaymentPriceDiv = styled.div `
    width:50px;
    height:32px;
    margin:0 10px 0 0;
`

const CompetitionMaxDiv = styled.div `
    width:50px;
    height:32px;
    margin:0 10px 0 0;
`

const CompetitionDescriptionDiv = styled.div `
    width:800px;
    height:100px;
    margin:0 10px 0 0;
`

const CompetitionIllustrationDiv = styled.div `
    width:auto;
    height:32px;
    margin:0 10px 0 0;
`

/**
 * Component
 * @param {*} param0
 * @returns
 */
const Competition = ({ match }) => {

    const { id } = useParams();
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [competitionName, setCompetitionName] = useState("");
    const [competitionSport, setCompetitionSport] = useState(0);
    const [competitionDate, setCompetitionDate] = useState("");
    const [competitionTime, setCompetitionTime] = useState("");
    const [competitionScoreMode, setCompetitionScoreMode] = useState(0);
    const [competitionStepConfiguration, setCompetitionStepConfiguration] = useState(0);
    const [competitionMinimalComposition, setCompetitionMinimalComposition] = useState("");
    const [competitionMaximalComposition, setCompetitionMaximalComposition] = useState("");
    const [competitionIndividualComposition, setCompetitionIndividualComposition] = useState(false);
    const [competitionPublishedCompetition, setCompetitionPublishedCompetition] = useState(false);
    const [competitionPrivateCompetition, setCompetitionPrivateCompetition] = useState(false);
    const [competitionMessagePrivateCompetition, setCompetitionMessagePrivateCompetition] = useState("");
    const [competitionCodePrivateCompetition, setCompetitionCodePrivateCompetition] = useState("");
    const [competitionOnlineRegistration, setCompetitionOnlineRegistration] = useState(false);
    const [competitionOnlineRegistrationClosing, setCompetitionOnlineRegistrationClosing] = useState(false);
    const [competitionOnlineRegistrationClosingDate, setCompetitionOnlineRegistrationClosingDate] = useState("");
    const [competitionOnlinePayment, setCompetitionOnlinePayment] = useState(false);
    const [competitionOnlinePaymentPrice, setCompetitionOnlinePaymentPrice] = useState("");
    const [competitionDescription, setCompetitionDescription] = useState("");
    const [competitionPlace, setCompetitionPlace] = useState("");
    const [competitionMax, setCompetitionMax] = useState(0);
    const [sports, setSports] = useState([]);
    const [scoreModes, setScoreModes] = useState([]);
    const [stepConfigurations, setStepConfigurations] = useState([]);
    const [renderButton, setRenderButton] = useState(0);

    const [isLoading, setIsLoading] = useState(true);
    const [isLoading_scoresModes, setIsLoading_scoresModes] = useState(true);
    const [isLoading_stepConfigurations, setIsLoading_stepConfigurations] = useState(true);
    const [isLoading_sports, setIsLoading_sports] = useState(true);
    const [isLoading_competition, setIsLoading_competition] = useState(false);
    const [isLoading_submitCompetition, setIsLoading_submitCompetition] = useState(false);

    /**
     * Refresh loaders
     */
    useEffect(() => {
        if(
            !isLoading_scoresModes &&
            !isLoading_stepConfigurations &&
            !isLoading_sports &&
            !isLoading_competition &&
            !isLoading_submitCompetition
        ) {
            setIsLoading(false);
        } else {
            setIsLoading(true);
        }
    }, [
        isLoading_scoresModes,
        isLoading_stepConfigurations,
        isLoading_sports,
        isLoading_competition,
        isLoading_submitCompetition
    ])

    /**
     * Load scoresmodes from database
     */
    useEffect(() => {

        const fetchScoresModes = async () => {

            try {

                // Deploy loaders
                setIsLoading_scoresModes(true);

                // Fetch data
                const {data, error} = await fetchAPI('GET', '/competition/getscoremodes/pro', {});

                // Manage errors
                if(error) throw new Error();

                // Fill data
                setScoreModes(data.scoreModes.map((s) => {
                    return {
                        value: s.key,
                        text: s.text
                    }
                }));

                // Hide loaders
                setIsLoading_scoresModes(false);

            }
            catch(e) {
                navigate('/e');
            }

        }

        fetchScoresModes();

    }, [navigate]);

    /**
     * Load step configurations from database
     */
    useEffect(() => {

        const fetchStepConfigurations = async () => {

            try {

                // Deploy loaders
                setIsLoading_stepConfigurations(true);

                // Fetch data
                const {data, error} = await fetchAPI('GET', '/competition/getstepconfigurations/pro', {});

                // Manage errors
                if(error) throw new Error();

                // Fill data
                setStepConfigurations(data.stepConfigurations.map((s) => {
                    return {
                        value: s.key,
                        text: s.text
                    }
                }));

                // Hide loaders
                setIsLoading_stepConfigurations(false)

            }
            catch(e) {

                navigate('/e');
                return;

            }

        }

        fetchStepConfigurations();

    }, [navigate]);

    /**
     * Load sports from database
     */
    useEffect(() => {

        const fetchSports = async () => {

            try {

                // Deploy loaders
                setIsLoading_sports(true);

                // Fetch data
                const {data, error} = await fetchAPI('GET', '/sport/getall', {});

                // Manage errors
                if(error) throw new Error();

                // Fill data
                setSports(data.sports.map((s) => {
                    return {
                        value: s.sportId,
                        text: s.name
                    }
                }));

                // Hide loaders
                setIsLoading_sports(false);

            }
            catch(e) {

                navigate('/e');
                return;

            }

        }

        fetchSports();

    }, [navigate]);

    /**
     * Fetch competition
     */
    useEffect(() => {

        const fetchCompetition = async () => {

            if(!id) return;

            try {

                // Deploy loaders
                setIsLoading_competition(true);

                // Fetch data
                const {data, error} = await fetchAPI("GET", '/competition/get/pro', {
                    competitionId: parseInt(id)
                });

                // Manage errors
                if(error) throw new Error();

                // Fill data
                setCompetitionName(data.competition ? data.competition.name : "");
                setCompetitionPlace(data.competition ? data.competition.place : "");
                setCompetitionDate(data.competition ? moment(new Date(data.competition.dte)).format('DD/MM/YYYY') : "");
                setCompetitionTime(data.competition ? moment(new Date(data.competition.dte)).format('HH:mm') : "");
                setCompetitionSport(data.competition ? data.competition.sportId : 0);
                setCompetitionMaximalComposition(data.competition ? "" + data.competition.maxPlayersByTeam + "" : null);
                setCompetitionMinimalComposition(data.competition ? "" + data.competition.minPlayersByTeam + "" : null);
                setCompetitionIndividualComposition(data.competition ? (data.competition.maxPlayersByTeam && data.competition.minPlayersByTeam && data.competition.maxPlayersByTeam === 1 && data.competition.minPlayersByTeam === 1 ? true : false) : false);
                setCompetitionPublishedCompetition(data.competition ? data.competition.published : false);
                setCompetitionPrivateCompetition(data.competition ? data.competition.private_ : false);
                setCompetitionMessagePrivateCompetition(data.competition ? data.competition.message : null);
                setCompetitionCodePrivateCompetition(data.competition ? data.competition.code : null);
                setCompetitionOnlineRegistration(data.competition ? data.competition.onlineSub : false);
                setCompetitionOnlinePayment(data.competition ? data.competition.onlinePay : false);
                setCompetitionOnlineRegistrationClosing(data.competition && data.competition.limitDteOnline ? true : false);
                setCompetitionOnlineRegistrationClosingDate(data.competition && data.competition.limitDteOnline ? moment(new Date(data.competition.limitDteOnline)).format('DD/MM/YYYY') : "");
                setCompetitionMax(data.competition && data.competition.max ? "" + data.competition.max + "" : "");
                setCompetitionOnlinePaymentPrice(data.competition ? "" + data.competition.price + "" : "");
                setCompetitionDescription(data.competition && data.competition.description ? data.competition.description : null);

                // Hide loaders
                setIsLoading_competition(false);

            }
            catch(e) {

                navigate('/e');
                return;

            }

        }

        fetchCompetition();

    }, [id, navigate])

    // Handlers

    const handleChangeCompetitionName = (e) => {setCompetitionName(e.target.value); setError(null); };
    const handleChangeCompetitionPlace = (e) => {setCompetitionPlace(e.target.value); setError(null); };

    const handleChangeCompetitionDate = (e) => {
        if(e.target.value.length === 2 || e.target.value.length === 5) setCompetitionDate(e.target.value + "/");
        else setCompetitionDate(e.target.value);
        setError(null);
    };

    const handleChangeCompetitionTime = (e) => {
        if(e.target.value.length === 2) setCompetitionTime(e.target.value + ":");
        else setCompetitionTime(e.target.value);
        setError(null);
    }

    const handleChangeCompetitionScoreMode = (e) => {setCompetitionScoreMode(e.target.value); setError(null); }
    const handleChangeCompetitionMinimalComposition = (e) => {setCompetitionMinimalComposition(e.target.value); setError(null); }
    const handleChangeCompetitionMaximalComposition = (e) => {setCompetitionMaximalComposition(e.target.value); setError(null); }
    const handleChangeCompetitionMax = (e) => {setCompetitionMax(e.target.value); setError(null); }
    const handleChangeCompetitionIndividualComposition = (e) => {setCompetitionIndividualComposition(e);setError(null);}

    const handleChangeCompetitionPublishedCompetition = (e) => {
        setCompetitionPublishedCompetition(e);
        if(e === false) {
            handleChangeCompetitionOnlineRegistration(false);
            handleChangeCompetitionPrivateCompetition(false);
        }
        setError(null);
    }

    const handleChangeCompetitionPrivateCompetition = (e) => {setCompetitionPrivateCompetition(e); setError(null); }
    const handleChangeCompetitionMessagePrivateCompetition = (e) => {setCompetitionMessagePrivateCompetition(e.target.value); setError(null); }

    const handleChangeCompetitionCodePrivateCompetition = (e) => {setCompetitionCodePrivateCompetition(e.target.value); setError(null); }

    const handleChangeCompetitionOnlineRegistration = (e) => {
        setCompetitionOnlineRegistration(e);
        if(e === false) {
            setCompetitionOnlineRegistrationClosing(false);
            setCompetitionOnlinePayment(false);
        }
        setError(null);
    }

    const handleChangeCompetitionOnlineRegistrationClosing = (e) => {
        setCompetitionOnlineRegistrationClosing(e);
        if(!e) setCompetitionOnlineRegistrationClosingDate("");
        setError(null);
    }

    const handleChangeCompetitionOnlineRegistrationClosingDate = (e) => {setCompetitionOnlineRegistrationClosingDate(e.target.value); setError(null); }
    const handleChangeCompetitionOnlinePayment = (e) => {setCompetitionOnlinePayment(e); setError(null); }
    const handleChangeCompetitionOnlinePaymentPrice = (e) => {setCompetitionOnlinePaymentPrice(e.target.value); setError(null); }
    const handleChangeCompetitionDescription = (e) => {setCompetitionDescription(e.target.value); setError(null); }
    const handleChangeCompetitionSport = (e) => {setCompetitionSport(e.target.value); setError(null); }
    const handleChangeCompetitionStepConfiguration = (e) => {setCompetitionStepConfiguration(e.target.value); setError(null); }

    const handleSubmitForm = async () => {

        try {

            // Deploy loaders
            setIsLoading_submitCompetition(true);

            try {

                if(id) {

                    // Try to validate the competition
                    competitionValidator(
                        competitionName,
                        competitionPlace,
                        competitionDate,
                        competitionTime,
                        competitionMinimalComposition,
                        competitionMaximalComposition,
                        competitionIndividualComposition,
                        competitionPublishedCompetition,
                        competitionPrivateCompetition,
                        competitionMessagePrivateCompetition,
                        competitionCodePrivateCompetition,
                        competitionOnlineRegistration,
                        competitionOnlineRegistrationClosing,
                        competitionOnlineRegistrationClosingDate,
                        competitionOnlinePayment,
                        competitionOnlinePaymentPrice,
                        competitionDescription,
                        1,
                        competitionSport,
                        1,
                        competitionMax
                    );

                }
                else {

                    // Try to validate competition
                    competitionValidator(
                        competitionName,
                        competitionPlace,
                        competitionDate,
                        competitionTime,
                        competitionMinimalComposition,
                        competitionMaximalComposition,
                        competitionIndividualComposition,
                        competitionPublishedCompetition,
                        competitionPrivateCompetition,
                        competitionMessagePrivateCompetition,
                        competitionCodePrivateCompetition,
                        competitionOnlineRegistration,
                        competitionOnlineRegistrationClosing,
                        competitionOnlineRegistrationClosingDate,
                        competitionOnlinePayment,
                        competitionOnlinePaymentPrice,
                        competitionDescription,
                        parseInt(competitionScoreMode),
                        competitionSport,
                        parseInt(competitionStepConfiguration),
                        parseInt(competitionMax)
                    );

                }
    

            }
            catch(e) {
                setError(e.message);
                setRenderButton(renderButton + 1);
                setIsLoading_submitCompetition(false);
                return;
            }

            /**
             * Internal fx to parse date and time and return an object
             * @param {*} dateStr
             * @param {*} timeStr
             * @returns
             */
            const parseDateAndTime = (dateStr, timeStr) => {
                const dateParts = dateStr.split('/');
                if (dateParts.length !== 3) return null;
                const [day, month, year] = dateParts.map(Number);
                if (isNaN(day) || isNaN(month) || isNaN(year)) return null;
                const timeParts = timeStr.split(':');
                if (timeParts.length !== 2) return null;
                const [hours, minutes] = timeParts.map(Number);
                if (isNaN(hours) || isNaN(minutes)) return null;
                return new Date(year, month - 1, day, hours, minutes);
            };

            // Initialize empty competition object
            let competition_ = null;

            // In case of a new competition
            if(!id) {
                competition_ = {
                    name: competitionName,
                    place: competitionPlace,
                    dte: parseDateAndTime(competitionDate, competitionTime),
                    minPlayersByTeam: !competitionIndividualComposition ? parseInt(competitionMinimalComposition) : 1,
                    maxPlayersByTeam: !competitionIndividualComposition ? parseInt(competitionMaximalComposition) : 1,
                    published: competitionPublishedCompetition,
                    private_: competitionPrivateCompetition,
                    message: competitionMessagePrivateCompetition,
                    code: competitionCodePrivateCompetition,
                    onlineSub: competitionOnlineRegistration,
                    onlinePay: competitionOnlinePayment,
                    limitDte: competitionOnlineRegistrationClosingDate ? parseDateAndTime(competitionOnlineRegistrationClosingDate, "00:00") : null,
                    price: competitionOnlinePaymentPrice ? parseInt(competitionOnlinePaymentPrice) : 0,
                    description: competitionDescription,
                    sportId: parseInt(competitionSport),
                    scoreMode: parseInt(competitionScoreMode),
                    stepConfiguration: parseInt(competitionStepConfiguration),
                    max: competitionMax ? parseInt(competitionMax) : null
                }
            }

            // In case of an update of an existing competition
            else {
                competition_ = {
                    name: competitionName,
                    place: competitionPlace,
                    dte: parseDateAndTime(competitionDate, competitionTime),
                    minPlayersByTeam: !competitionIndividualComposition ? parseInt(competitionMinimalComposition) : 1,
                    maxPlayersByTeam: !competitionIndividualComposition ? parseInt(competitionMaximalComposition) : 1,
                    published: competitionPublishedCompetition,
                    private_: competitionPrivateCompetition,
                    message: competitionMessagePrivateCompetition,
                    code: competitionCodePrivateCompetition,
                    onlineSub: competitionOnlineRegistration,
                    onlinePay: competitionOnlinePayment,
                    limitDte: competitionOnlineRegistrationClosingDate ? parseDateAndTime(competitionOnlineRegistrationClosingDate, "00:00") : null,
                    price: competitionOnlinePaymentPrice ? parseInt(competitionOnlinePaymentPrice) : 0,
                    description: competitionDescription,
                    sportId: parseInt(competitionSport),
                    max: competitionMax ? parseInt(competitionMax) : null,
                    competitionId: id
                }
            }

            // Definition of API request parameters
            let method = "POST";
            let endpoint = "create";
            if(id) method = "PUT";
            if(id) endpoint = "update";

            // Push data
            const {error} = await fetchAPI(method, '/competition/' + endpoint + '/pro', {
                competition: competition_
            });

            // Manage errors
            if(error) throw new Error();

            navigate('/', {
                state: {
                    success: "La compétition a été modifiée"
                }
            })
            return;

        }
        catch(e) {

            navigate('/e');
            return;

        }

    }

    return (

        <Wrapper popup={[error]} isLoading={isLoading}>

            <HeaderWrapper title="Créer une compétition" showSearchEngine={false} />

            <Section align="sb">

                <FormBlock>
                    <label>Nom</label>
                    <CompetitionNameDiv>
                        <TextInput placeholder="Nom de la compétition" value={competitionName} onChange={handleChangeCompetitionName} isLoading={isLoading} />
                    </CompetitionNameDiv>
                </FormBlock>

                <FormBlock>
                    <label>Lieu</label>
                    <CompetitionPlaceDiv>
                        <TextInput placeholder="Lieu de la compétition" value={competitionPlace} onChange={handleChangeCompetitionPlace} isLoading={isLoading} />
                    </CompetitionPlaceDiv>
                </FormBlock>

                <FormBlock>
                    <label>Date</label>
                    <CompetitionDateDiv>
                        <TextInput value={competitionDate} onChange={handleChangeCompetitionDate} placeholder="dd/mm/yyyy" isLoading={isLoading} />
                    </CompetitionDateDiv>
                </FormBlock>

                <FormBlock>
                    <label>Heure</label>
                    <CompetitionTimeDiv>
                        <TextInput value={competitionTime} onChange={handleChangeCompetitionTime} placeholder="hh:mm" isLoading={isLoading} />
                    </CompetitionTimeDiv>
                </FormBlock>

                <FormBlock>
                    <label>Sport</label>
                    <CompetitionSportDiv>
                        <SelectInput options={sports} value={competitionSport} onChange={handleChangeCompetitionSport} isLoading={isLoading} />
                    </CompetitionSportDiv>
                </FormBlock>

            </Section>

            {

            !id ?

            <Section title="Calcul des scores" align="start">

                <FormBlockCategory title="Comptage des points" />

                <FormBlock>
                    <label>Paliers</label>
                    <CompetitionPaliersDiv>
                        <SelectInput options={stepConfigurations} value={competitionStepConfiguration} onChange={handleChangeCompetitionStepConfiguration} isLoading={isLoading} />
                    </CompetitionPaliersDiv>
                </FormBlock>

                <FormBlock>
                    <label>Average</label>
                    <CompetitionAverageDiv>
                        <SelectInput options={scoreModes} value={competitionScoreMode} onChange={handleChangeCompetitionScoreMode} isLoading={isLoading} />
                    </CompetitionAverageDiv>
                </FormBlock>

            </Section>

            :

            null

            }

            <Section title="Equipes" align="start">

                <FormBlockCategory title="Composition" />

                <FormBlock>
                    <div>
                        <CompetitionNumberTeamDiv>
                            <TextInput value={competitionMinimalComposition} onChange={handleChangeCompetitionMinimalComposition} isDisabled={competitionIndividualComposition} isLoading={isLoading} />
                        </CompetitionNumberTeamDiv>
                        à
                        <CompetitionNumberTeamDiv>
                            <TextInput value={competitionMaximalComposition} onChange={handleChangeCompetitionMaximalComposition} isDisabled={competitionIndividualComposition} isLoading={isLoading} />
                        </CompetitionNumberTeamDiv>
                        joueurs
                    </div>
                </FormBlock>

                <FormBlock>
                    <CompetitionIndividualDiv>
                        <CheckboxInput value={competitionIndividualComposition} onChange={handleChangeCompetitionIndividualComposition} isLoading={isLoading} />
                    </CompetitionIndividualDiv>
                    <label>Compétition individuelle</label>
                </FormBlock>

            </Section>

            <Section title="Hub" align="sb">

                <FormBlockCategory title="Accessibilité" />

                <FormBlock>
                    <CompetitionPublishedDiv>
                        <CheckboxInput value={competitionPublishedCompetition} onChange={handleChangeCompetitionPublishedCompetition} isLoading={isLoading} />
                    </CompetitionPublishedDiv>
                    <label>Compétition publiée</label>
                </FormBlock>

                <FormBlock>
                    <CompetitionPrivateDiv>
                        <CheckboxInput value={competitionPrivateCompetition} onChange={handleChangeCompetitionPrivateCompetition} isDisabled={!competitionPublishedCompetition} isLoading={isLoading} />
                    </CompetitionPrivateDiv>
                    <label>Compétition privée</label>
                    <CompetitionPrivateMessageDiv>
                        <TextInput placeholder="Message (ex: 'Competition réservée à ...')" value={competitionMessagePrivateCompetition} onChange={handleChangeCompetitionMessagePrivateCompetition} isDisabled={!competitionPrivateCompetition} isLoading={isLoading} />
                    </CompetitionPrivateMessageDiv>
                </FormBlock>

                <FormBlock>
                    <CompetitionPrivateCodeDiv>
                        <TextInput placeholder="Code d'inscription" value={competitionCodePrivateCompetition} onChange={handleChangeCompetitionCodePrivateCompetition} isDisabled={!competitionPrivateCompetition} isLoading={isLoading} />
                    </CompetitionPrivateCodeDiv>
                </FormBlock>

            </Section>

            <br />

            <Section align="start">

                <FormBlockCategory title="Inscription" />

                <FormBlock>
                    <CompetitionOnlineRegistrationDiv>
                        <CheckboxInput value={competitionOnlineRegistration} onChange={handleChangeCompetitionOnlineRegistration} isDisabled={!competitionPublishedCompetition} isLoading={isLoading} />
                    </CompetitionOnlineRegistrationDiv>
                    <label>Activer l'inscription en ligne</label>
                </FormBlock>

                <FormBlock>
                    <CompetitionOnlineRegistrationClosingDiv>
                        <CheckboxInput value={competitionOnlineRegistrationClosing} onChange={handleChangeCompetitionOnlineRegistrationClosing} isDisabled={!competitionOnlineRegistration} isLoading={isLoading} />
                    </CompetitionOnlineRegistrationClosingDiv>
                    <label>Programmer la clôture au</label>
                    <CompetitionOnlineRegistrationClosingDateDiv>
                        <TextInput placeholder="28/07/2024" value={competitionOnlineRegistrationClosingDate} onChange={handleChangeCompetitionOnlineRegistrationClosingDate} isDisabled={!competitionOnlineRegistrationClosing} isLoading={isLoading} />
                    </CompetitionOnlineRegistrationClosingDateDiv>
                </FormBlock>

                <FormBlock>
                    <label>Limite</label>
                    <CompetitionMaxDiv>
                        <TextInput value={competitionMax} onChange={handleChangeCompetitionMax} isDisabled={!competitionOnlineRegistration} isLoading={isLoading} />
                    </CompetitionMaxDiv>
                    équipes
                </FormBlock>

            </Section>

            <br />

            <Section align="start">

                <FormBlockCategory title="Paiement en ligne" />

                <FormBlock>
                    <CompetitionOnlinePaymentDiv>
                        <CheckboxInput value={competitionOnlinePayment} onChange={handleChangeCompetitionOnlinePayment} isDisabled={!competitionOnlineRegistration} isLoading={isLoading} />
                    </CompetitionOnlinePaymentDiv>
                    <label>Activer le paiement en ligne</label>
                    <CompetitionOnlinePaymentPriceDiv>
                        <TextInput placeholder="Prix" value={competitionOnlinePaymentPrice} onChange={handleChangeCompetitionOnlinePaymentPrice} isLoading={isLoading} />
                    </CompetitionOnlinePaymentPriceDiv>
                    € par joueur
                </FormBlock>

            </Section>

            <br /><br />

            <Section align="start">

                <FormBlockCategory title="Social" />

                <FormBlock>
                    <CompetitionDescriptionDiv>
                        <TextareaInput placeholder="Description de l'évènement" value={competitionDescription} onChange={handleChangeCompetitionDescription} isDisabled={!competitionPublishedCompetition} isLoading={isLoading} />
                    </CompetitionDescriptionDiv>
                    <CompetitionIllustrationDiv>
                        <FileInput isDisabled={!competitionPublishedCompetition} isLoading={isLoading} />
                    </CompetitionIllustrationDiv>
                </FormBlock>

                <ValidationSection icon="save" message1="Enregistrer" message2="la compétition" callback={handleSubmitForm} renderButton={renderButton} />

            </Section>

        </Wrapper>

    );

}

export default Competition;