import styled from "styled-components";
import CompetitionModel from "../../../../utils/modelConverters/competition";
import { useNavigate } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import { useEffect, useState } from "react";
import ButtonsArea from "../../Table/Row/ButtonsArea/ButtonsArea";
import Button from "../../Table/Row/ButtonsArea/Button/Button";
import { useCookies } from "react-cookie";

/**
 * Styles
 */
const CompetitionBlockBox = styled.div `

    height: 74px;
    flex-shrink: 0;
    width: 98%;
    border-radius: 8px;
    margin: 10px 1%;
    box-shadow: 0px 0px 13px -5px rgba(0, 0, 0, 0.36);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    position:relative;

    &.fade-enter {
        opacity: 0;
        transform: translateY(-10px);
    }
    &.fade-enter-active {
        opacity: 1;
        transform: translateY(0);
        transition: opacity 300ms, transform 300ms;
    }
    &.fade-exit {
        opacity: 1;
        transform: translateY(0);
    }
    &.fade-exit-active {
        opacity: 0;
        transform: translateY(-10px);
        transition: opacity 300ms, transform 300ms;
    }

    &:hover {
        background: #ecf0f1;
        transition: all .3s ease;
    }

    & > div {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
        position:absolute;
    }

`;

const GeneralInfoBlock = styled.div `

    left: 15px;

`

const CompetitionNameText = styled.div `
        
    font-size: 18px;
    font-weight: 700;

`

const CompetitionDateText = styled.div `
        
    font-weight: 600;
    color: grey;

`

const SportInfoBlock = styled.div `

    left: 25%;

`

const SportNameText = styled.span `

    font-weight: 600;
    color: #f1c40f;

`

const NbTeamsText = styled.span `

    font-weight: 600;
    color: #f1c40f;

`

const SubscriptionInfoBlock = styled.div `

    left: 40%;

`

const SubscriptionStatusText = styled.span `

    font-weight: 800;
    text-transform: uppercase;

`

const LimitText = styled.span `

    font-weight: 600;
    color: #f1c40f;

`

const StatusBlock = styled.div `
        
    align-items: center;
    font-weight: bold;
    left:60%;

`

const SpanCategory = styled.span `

    font-weight: 700;
    color: black;
    text-transform:none;

`

/**
 * Component
 * @param {*} props 
 * @returns 
 */
const CompetitionBlock = (props) => {

    // Load cookies
    const [cookies, setCookies] = useCookies(["competition"]);
    const navigate = useNavigate();

    // Load status
    const [loaded, setLoaded] = useState(false);

    /**
     * Loading animation
     */
    useEffect(() => {
        setLoaded(true);
        return () => setLoaded(false);
    }, [props.competition]);

    /**
     * Open competition handler
     * @param {*} id 
     */
    const openDashboard = (id) => {
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 7);
        setCookies('competition', parseInt(id), { path: '/' });
        navigate("/dashboard");
    };

    return (

        props.competition ?

            <CSSTransition in={loaded} timeout={300} classNames="fade" unmountOnExit>

                <CompetitionBlockBox onMouseOver={props.onHover} onClick={() => openDashboard(props.competition.competitionId)}>
                    <GeneralInfoBlock>
                        <CompetitionNameText>{props.competition.name}</CompetitionNameText>
                        <CompetitionDateText>{CompetitionModel.getFormattedDate(props.competition)}</CompetitionDateText>
                    </GeneralInfoBlock>
                    <SportInfoBlock>
                        <SportNameText><SpanCategory>Sport: </SpanCategory>{props.competition.sportName}</SportNameText>
                        <NbTeamsText><SpanCategory>Equipes: </SpanCategory>{props.competition.nbTeams}</NbTeamsText>
                    </SportInfoBlock>
                    <SubscriptionInfoBlock>
                        <SubscriptionStatusText className={CompetitionModel.getFormattedOnlineSubscriptionStatus(props.competition).className}>
                            <SpanCategory>Inscription: </SpanCategory>{CompetitionModel.getFormattedOnlineSubscriptionStatus(props.competition).text}
                        </SubscriptionStatusText>
                        <LimitText><SpanCategory>Limite: </SpanCategory>{CompetitionModel.getFormattedJauge(props.competition)}</LimitText>
                    </SubscriptionInfoBlock>
                    <StatusBlock>
                        <span className={CompetitionModel.getFormattedStatus(props.competition).className}>
                            {CompetitionModel.getFormattedStatus(props.competition).text}
                        </span>
                    </StatusBlock>
                    <ButtonsArea>
                        <Button type="error" onClick={(event) => props.deleteCompetition(event, props.competition.competitionId)} icon="trash" />
                    </ButtonsArea>
                </CompetitionBlockBox>

            </CSSTransition>

            :

            null

    );

};

export default CompetitionBlock;
