const illustrativePlayerValidator = (firstname, name) => {

    if(typeof name !== "string") throw new Error("Le nom est invalide.");
    if(typeof firstname !== "string") throw new Error("Le prénom est invalide.");
    if(name.length === 0) throw new Error("Le nom ne peut pas être vide.");
    if(name.length > 50) throw new Error("Le nom est trop long.");
    if(firstname.length === 0) throw new Error("Le prénom ne peut pas être vide.");
    if(firstname.length > 50) throw new Error("Le prénom est trop long.");
    return;

}

export default illustrativePlayerValidator;