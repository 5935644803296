import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components"

/**
 * Styles
 */

const LinkButton = styled(Link) `

    width:100%;
    text-decoration:none;

`

const BtnWrapper = styled.div `

    display:flex;
    flex-direction:row;
    width:100%;
    height:100%;
    background:#2ecc71;
    background: ${props => props.color};
    justify-content:start;
    align-items:center;
    transition:all .3s ease;
    border-radius:8px;

    & > ion-icon {
        font-size:48px;
        color:white;
        margin:0 20px;
    }

    &:hover {
        transform:scale(1.02);
        cursor:pointer;
        transition:all .3s ease;    
    }

    &.clicked, &.clicked:hover {

        background:grey;
        cursor:wait;

    }

`

const Message = styled.p `

    margin:18px 0;
    color:white;
    font-weight:600;

`

/**
 * Component
 * @param {*} props 
 * @returns 
 */
const LargeButton = (props) => {

    const [isClicked, setIsClicked] = useState(false);

    const onClick = (e) => {
        if(isClicked) return;
        if(!props.noloader) setIsClicked(true);
        props.callback(e);
    }

    useEffect(() => {
        setIsClicked(false);
    }, [props.renderButton]);

    // Determine the type of icon to display
    const icon = props.icon ? props.icon : "add-circle";
    const color = props.color ? props.color : "#2ecc71";

    return (

        <LinkButton onClick={onClick} className={props.small && "small"}>
            
            <BtnWrapper color={color} className={ isClicked ? " clicked" : "" }>
                <ion-icon name={icon}></ion-icon>
                <Message>{props.message1}{ props.message2 ? <><br />{props.message2}</> : ""}</Message>
            </BtnWrapper>
            
        </LinkButton>

    );
    
}

export default LargeButton;