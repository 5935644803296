import styled from "styled-components";
import Row from "../../Table/Row/Row";
import fieldModelConverter from "../../../../utils/modelConverters/field";
import { CSSTransition } from "react-transition-group";
import { useEffect, useState } from "react";
import ButtonsArea from "../../Table/Row/ButtonsArea/ButtonsArea";
import Button from "../../Table/Row/ButtonsArea/Button/Button";
import { useNavigate } from "react-router-dom";

const FieldBlockArea = styled(Row) `

    &.fade-enter {
        opacity: 0;
        transform: translateY(-10px);
    }
    &.fade-enter-active {
        opacity: 1;
        transform: translateY(0);
        transition: opacity 300ms, transform 300ms;
    }
    &.fade-exit {
        opacity: 1;
        transform: translateY(0);
    }
    &.fade-exit-active {
        opacity: 0;
        transform: translateY(-10px);
        transition: opacity 300ms, transform 300ms;
    }

`

const FieldNameBox = styled.div `

    position:absolute;
    left:0;
    margin:0 0 0 20px;

`

const FieldCoeffBox = styled.div `

    position:absolute;
    left:30%;

`

const FieldStatusBox = styled.div `

    position:absolute;
    left:86%;
    font-weight:600;

`

/**
 * Component
 * @param {*} props 
 * @returns 
 */
const FieldBlock = (props) => {

    // Load navigation
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);

    /**
     * Loading animation
     */
    useEffect(() => {
        setLoaded(true);
        return () => setLoaded(false);
    }, [props.level]);

    /**
     * Handler for navigation to field
     */
    const navigateToFieldHandler = () => {
        navigate('/field/' + props.field.fieldId);
    }

    return (

        props.field && props.deleteField &&

        <CSSTransition in={loaded} timeout={300} classNames="fade" unmountOnExit>
            <FieldBlockArea onClick={navigateToFieldHandler}>
                <FieldNameBox>{props.field.name}</FieldNameBox>
                <FieldCoeffBox>{props.field.coeff}</FieldCoeffBox>
                <FieldStatusBox className={fieldModelConverter.getFormattedAvailableStatus(props.field).className}>{ fieldModelConverter.getFormattedAvailableStatus(props.field).text }</FieldStatusBox>
                <ButtonsArea>
                    <Button type="error" onClick={(event) => props.deleteField(event, props.field.fieldId)} icon="trash" />
                </ButtonsArea>
            </FieldBlockArea>
        </CSSTransition>

    );
    
}

export default FieldBlock;