import styled from "styled-components";
import PouleDragNDropBlock from "./PouleDragNDropBlock/PouleDragNDropBlock";
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useEffect } from "react";

const StyledDiv = styled.div `

    position:relative;
    top:0;
    left:0;
    width:calc(100% - 60px);
    margin:30px;
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;

`

const PouleDragNDropList = (props) => {

    return (

        props.poules && props.onDragEnd && props.handleDeletePouleToCreate && props.handleChangeFieldId && props.fields &&

        <DragDropContext onDragEnd={props.onDragEnd}>
            <StyledDiv>
                {props.poules.map((p) => (
                    <Droppable key={p.id} droppableId={ p.id.toString() }>
                        {(provided) => (
                            <div 
                                ref={provided.innerRef} 
                                {...provided.droppableProps}
                            >
                                <PouleDragNDropBlock fieldId={p.fieldId} teams={p.teams} pouleId={p.id} handleDeletePouleToCreate={props.handleDeletePouleToCreate} handleChangeFieldId={(e) => props.handleChangeFieldId(e, p.id)} fields={props.fields}  />
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                ))}
            </StyledDiv>
        </DragDropContext>

    );   

}

export default PouleDragNDropList;