import styled from "styled-components";
import playerConverter from "../../../../utils/modelConverters/player";
import Row from "../../Table/Row/Row";
import ButtonsArea from "../../Table/Row/ButtonsArea/ButtonsArea";
import Button from "../../Table/Row/ButtonsArea/Button/Button";

/**
 * Styles
 */

const StyledDiv = styled(Row) `

    &:hover {
        background:#ecf0f1;
        transition:all .3s ease;
    }

    & span.neo {
        color:#f1c40f;
        font-size:12px;
        padding-right:5px;
        font-weight:bold;
    }

    & > div:first-child {
        position:absolute;
        left:1%;
    }

    & > div:nth-child(2) {
        position:absolute;
        left:14%;
    }

    & > div:nth-child(3) {
        position:absolute;
        left:30%;
    }

    & > div:nth-child(4) {
        position:absolute;
        left:60%;
    }

    & > div:nth-child(5) {
        position:absolute;
        left:75%;
    }

    & > div:nth-child(6) {
        position:absolute;
        left:85%;
    }

`

/**
 * Component
 * @param {*} props 
 * @returns 
 */
const PlayerBlock = (props) => {

    // Handler
    const deletePlayer = (e) => {
        e.stopPropagation();
        props.onDeletePlayer(props.player.email);
    }

    return (
        <StyledDiv onClick={() => props.onClick(props.player)}>

            <div className="firstname">{props.player.firstname}</div>
            <div>{props.player.name}</div>
            <div><span className="neo">{props.player.playerId ? "NEO" : ""}</span> {props.player.email}</div>
            <div>{props.player.phone}</div>
            <div>{ playerConverter.getFormattedYearsOld(props.player.birthDate) }</div>
            <div className={playerConverter.getFormattedBilling(props.player.payed).className}>{playerConverter.getFormattedBilling(props.player.payed).text}</div>

            <ButtonsArea>
                <Button type="error" onClick={deletePlayer} icon="trash" />
            </ButtonsArea>

        </StyledDiv>
    );

}

export default PlayerBlock;