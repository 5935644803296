import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Software from './pages/Software';
import Dashboard from './pages/Software/Dashboard';
import Teams from './pages/Software/Teams';
import Competitions from './pages/Software/Competitions';
import Team from './pages/Software/Team';
import Competition from './pages/Software/Competition';
import Levels from './pages/Software/Levels';
import Level from './pages/Software/Level';
import Fields from './pages/Software/Fields';
import Field from './pages/Software/Field';
import Authentication from './pages/Authentication';
import Manager from './pages/Software/Manager';
import { CookiesProvider } from 'react-cookie';
import Account from './pages/Software/Account';
import Error from './pages/Error';

function App() {

  return (

    <CookiesProvider>

      <BrowserRouter>

        <Routes>

          <Route path='/login' element={<Authentication page="login" />} />
          <Route path='/logout' element={<Authentication page="logout" />} />
          <Route path='/forgot' element={<Authentication page="forgot" />} />

          <Route path='/e' element={<Error />} />

          <Route path='/' element={<Software />}>

            <Route index path='/' element={<Competitions />} />

            <Route index path='/account' element={<Account />} />

            <Route path='/dashboard' element={<Dashboard />} />

            <Route path='/teams' element={<Teams />} />
            <Route path='/team/:id' element={<Team />} />
            <Route path='/add-team' element={<Team />} />

            <Route path='/levels' element={<Levels />} />
            <Route path='/level/:id' element={<Level />} />
            <Route path='/add-level' element={<Level />} />

            <Route path='/fields' element={<Fields />} />
            <Route path='/field/:id' element={<Field />} />
            <Route path='/add-field' element={<Field />} />

            <Route path='/competition/:id' element={<Competition />} />
            <Route path='/add-competition' element={<Competition />} />

            <Route path='/manager' element={<Manager />} />
            <Route path='/manager/:id' element={<Manager />} />

          </Route>

        </Routes>

      </BrowserRouter>

    </CookiesProvider>

  )

}

export default App;
