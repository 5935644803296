import { useEffect, useState } from "react"
import styled, { keyframes } from "styled-components"

/**
 * Styles
 */

const StyledInput = styled.div `

    border-radius:8px;
    border:1px solid black;
    background:white;
    padding:0;
    font-weight:550;
    outline:0;
    width:100%;
    height:100%;
    transition:all .3s ease;
    cursor:pointer;
    display:flex;
    justify-content:center;
    align-items:center;
    font-size:22px;
    font-weight:600;
    color:white;

    &.checked {
        background:#2c3e50;
    }

    &.disabled {
        background:grey;
        cursor:default;
    }

`

/**
 * Component
 * @param {*} props 
 * @returns 
 */
const CheckboxInput = (props) => {

    const [checked, setChecked] = useState(false);
    const [className, setClassName] = useState("");

    const toggle = () => {
        if(!props.isDisabled) {
            props.onChange(checked ? false : true);
            setChecked(checked ? false : true);
        }
    }

    useEffect(() => {

        let cn = "";

        cn += props.value ? "checked " : "";
        if(props.isDisabled) cn += "disabled ";
        cn += props.isLoading ? "loading_box" : "loaded_box"

        setClassName(cn);
        setChecked(props.value);

    }, [props.isDisabled, props.isLoading, props.value]);

    return (
        <StyledInput onClick={toggle} className={className}>{checked ? "X" : ""}</StyledInput>
    );

}

export default CheckboxInput;