import styled from "styled-components";
import ValidationSection from "../../components/Software/ValidationSection/ValidationSection";
import TextInput from "../../components/Software/Inputs/TextInput";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import fieldValidator from "../../utils/validators/field";
import Wrapper from "../../components/Software/Wrapper/Wrapper";
import Popup from "../../components/Software/Popup/Popup";
import Section from "../../components/Software/Section/Section";
import FormBlock from "../../components/Software/FormBlock/FormBlock";
import fetchAPI from "../../utils/fetch";
import { useCookies } from "react-cookie";
import NumberInput from "../../components/Software/Inputs/NumberInput";
import HeaderWrapper from "../../components/Software/Wrapper/HeaderWrapper/HeaderWrapper";

/**
 * Styles
 */

const FieldNameBlock = styled.div `

    width:300px;
    height:32px;

`

const FieldCoefficientBlock = styled.div `

    width:82px;
    height:32px;
    
`

/**
 * Component
 * @param {*} param0 
 * @returns 
 */
const Field = ({ match }) => {

    const { id } = useParams();
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [cookies] = useCookies(['competition']); 
    const [fieldName, setFieldName] = useState("");
    const [fieldCoeff, setFieldCoeff] = useState(0);

    /**
     * Load field from database
     */
    useEffect(() => {

        if(!id) return;

        const fetchField = async () => {

            try {

                setIsLoading(true);

                const {data, error} = await fetchAPI("GET", "/field/get/pro", {
                    fieldId: parseInt(id)
                });

                if(error) throw new Error();

                setFieldName(data && data.field ? data.field.name : "");
                setFieldCoeff(data && data.field ? data.field.coeff : 1);
                setIsLoading(false);

            }
            catch(e) {
                navigate('/e');
                return;
            }

        }

        fetchField();

    }, [id, navigate]);

    const handleChangefieldName = (e) => {setFieldName(e.target.value); setError(null); };
    const handleChangefieldCoeff = (e) => {setFieldCoeff(e.target.value); setError(null); };

    /**
     * Submit form handler
     */
    const handleSubmitForm = async () => {

        try {

            fieldValidator(fieldName, parseInt(fieldCoeff));

            let method = 'POST';
            let endpoint = 'create';

            let field = {
                name: fieldName,
                coeff: parseInt(fieldCoeff),
                competitionId: cookies.competition
            }

            if(id) {
                method = "PUT";
                endpoint = "update"
                field = {
                    ...field,
                    fieldId: id
                }
            }

            try {

                const {error} = await fetchAPI(method, '/field/' + endpoint + '/pro', {
                    field: field
                });
    
                if(error) throw new Error();

                setError(null);

                navigate('/fields', {
                    state: {
                        success: "Le terrain a été créé/modifié."
                    }
                });

            }
            catch(e) {
                navigate('/e');
            }

        }
        catch(e) {
            navigate('/e');
        }

    }

    return (

        <Wrapper isLoading={isLoading} popup={[error]}>

            <HeaderWrapper title={id ? "Modifier le terrain" : "Ajouter un terrain"} showSearchEngine={false} />

            <Section align="start">

                <FormBlock>
                    <label>Nom</label>
                    <FieldNameBlock>
                        <TextInput placeholder="Nom du terrain" value={fieldName} onChange={handleChangefieldName} />
                    </FieldNameBlock>
                </FormBlock>

                <FormBlock>
                    <label>Coefficient</label>
                    <FieldCoefficientBlock>
                        <NumberInput placeholder="Coeff" value={fieldCoeff} onChange={handleChangefieldCoeff} />
                    </FieldCoefficientBlock>
                </FormBlock>

            </Section>

            <ValidationSection icon="save" message1="Enregistrer" message2="le terrain" callback={handleSubmitForm} />

        </Wrapper>
        
    );

}

export default Field;