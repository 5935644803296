import styled from "styled-components"
import SearchResults from "./SearchResults/SearchResults";
import { useEffect, useState } from "react";
import fetchAPI from "../../../../../utils/fetch";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

/**
 * Styles
 */

const StyledInput = styled.input `

    transition:all .3s ease;
    height:50px;
    margin-left:40px;
    outline:0;
    color:#34495e;
    width:800px;
    background:#ecf0f1;
    border:0;
    border-radius:8px;
    padding-left:15px;
    font-size:16px;
    font-weight:600;

    &:hover {
        transition:all .3s ease;
        background:#d9dadb;
    }

`

/**
 * Component
 * @returns 
 */
const Searchbar = () => {

    const navigate = useNavigate();
    const [cookies] = useCookies(["competition"]);
    const [showResults, setShowResults] = useState(false);
    const [results, setResults] = useState([]);
    const [keyword, setKeyWord] = useState("");

    // Handlers

    const handleSearch = () => {
        setShowResults(true);
    }

    const handleUpdateSearch = (e) => {

        // DO NOT show results
        setShowResults(true);

        // Manage empty search case
        if(!e.target.value || e.target.value === "") {
            setResults([]);
            setKeyWord("");
            return;
        }

        // Inject keyword from DOM
        const keyword_ = e.target.value;

        const launchSearch = async () => {

            try {

                // Fetch data
                const {data, error} = await fetchAPI('GET', '/general/search/pro', {
                    competitionId: cookies.competition,
                    keyword: keyword_
                });
                
                // Manage errors
                if(error) throw new Error();
                
                // Fill data
                setResults(data.results);

            }
            catch(e) {

                navigate('/e');
                return;

            }

        }

        // Launch search 
        launchSearch();

        // Save keyword
        setKeyWord(keyword_);

    }

    const handleKeyDown = (event) => {

        if(!results || results.length === 0) return;

        if (event.key === 'Enter') {
            handleNavigate(results[0].elementType, results[0].elementId);
            return;
        }

      };

    const handleNavigate = (t, id) => {

        setShowResults(false);
        setKeyWord("");
        setResults([]);
        
        navigate('/' + t + '/' + id);

    }

    const handleExitSearch = () => {
        setShowResults(false);  
    }

    return (
        <>
            { cookies.competition ? <StyledInput placeholder="Rechercher dans Neoscore ..." value={keyword} onFocus={handleSearch} onBlur={handleExitSearch} onChange={handleUpdateSearch} onKeyDown={handleKeyDown} />: <div></div>}
            { showResults && <SearchResults results={results} handleNavigate={handleNavigate} />}
        </>
    )
}

export default Searchbar;