import styled from "styled-components";
import Log from "./Log/Log";
import { useEffect, useState } from "react";

/**
 * Styles
 */

const LogsWrapper = styled.div `

    position:relative;
    top:0;
    left:0;
    width:100%;
    display:flex;
    flex-direction:column;
    overflow-y:auto;
    overflow-x:hidden;

`

/**
 * Component
 * @returns 
 */
const Logs = (props) => {

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(props.isLoading);
    }, [props.isLoading]);


    return (

        props.logs &&

        <LogsWrapper className={isLoading ? "loading_content" : "loaded_content"}>
            { props.logs.map((log) => (
                <Log date={log.createdAt} type={log.tpe} content={log.content} key={log.logId} />
            ))}
        </LogsWrapper>

    );

}

export default Logs;