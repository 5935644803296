import styled from "styled-components";
import TeamModel from "../../../../utils/modelConverters/team"
import Row from "../../Table/Row/Row";
import ButtonsArea from "../../Table/Row/ButtonsArea/ButtonsArea";
import Button from "../../Table/Row/ButtonsArea/Button/Button";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";

/**
 * Styles
 */

const TeamBlockArea = styled(Row) `


    &.confirmed {
        border-left: 10px solid #2ecc71;
    }

    &.notconfirmed {
        border-left: 10px solid #e74c3c;
    }

    &.fade-enter {
        opacity: 0;
        transform: translateY(-10px);
    }

    &.fade-enter-active {
        opacity: 1;
        transform: translateY(0);
        transition: opacity 300ms, transform 300ms;
    }

    &.fade-exit {
        opacity: 1;
        transform: translateY(0);
    }
        
    &.fade-exit-active {
        opacity: 0;
        transform: translateY(-10px);
        transition: opacity 300ms, transform 300ms;
    }

`

const TeamNameBox = styled.div `
    
    position:absolute;
    left:20px;

`

const NbPlayersBox = styled.div `
    
    position:absolute;
    left:14%;

`

const RegistrationDateBox = styled.div `
    
    position:absolute;
    left:25%;

`

const CanalBox = styled.div `
    
    position:absolute;
    left: 40%;

`

const LevelNameBox = styled.div `
    
    position:absolute;
    left: 48%;

`

const ConfirmationBox = styled.div `
    
    position:absolute;
    left: 58%;

`

const PaymentStatusBox = styled.div `
    
    position:absolute;
    left: 70%;

`

/**
 * Component
 * @param {*} props 
 * @returns 
 */
const TeamBlock = (props) => {

    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);

    /**
     * Loading animation
     */
    useEffect(() => {
        setLoaded(true);
        return () => setLoaded(false);
    }, [props.team]);

    const confirmed = props.team.team ? props.team.team.confirmed : false;

    const handleOpen = () => {
        navigate("/team/" + props.team.team.teamId);
    }

    return (

        props.team.team && props.deleteTeam &&

        <CSSTransition in={loaded} timeout={300} classNames="fade" unmountOnExit>
            <TeamBlockArea onMouseOver={props.onHover} onClick={handleOpen} className={confirmed ? "confirmed" : "notconfirmed"}>
                
                <TeamNameBox>{props.team.team.name}</TeamNameBox>
                <NbPlayersBox>{props.team.team.players.length + props.team.team.illustrativePlayers.length} joueurs</NbPlayersBox>
                <RegistrationDateBox>{ TeamModel.getFormattedRegistrationDate(props.team.team)}</RegistrationDateBox>
                <CanalBox>{ TeamModel.getFormattedCanal(props.team.team) }</CanalBox>
                <LevelNameBox>{props.team.team.level.name}</LevelNameBox>
                <ConfirmationBox className={ TeamModel.getFormattedConfirmation(props.team.team).className }>{ TeamModel.getFormattedConfirmation(props.team.team).text }</ConfirmationBox>
                <PaymentStatusBox className={ TeamModel.getFormattedPayment(props.team.team).className }>{ TeamModel.getFormattedPayment(props.team.team).text }</PaymentStatusBox>

                <ButtonsArea>
                    { props.team.deletable && <Button type="info large" onClick={(event) => props.handleDerivate(event, props.team.team)} text="Engager" /> }
                    { confirmed && <Button type="error" onClick={(event) => props.confirmTeam(event, props.team.team.teamId, false)} icon="alert-outline" /> }
                    { !confirmed && <Button type="success" onClick={(event) => props.confirmTeam(event, props.team.team.teamId, true)} icon="checkmark-outline" /> }
                    { props.team.deletable && <Button type="error" onClick={(event) => props.deleteTeam(event, props.team.team.teamId)} icon="trash" />} 
                </ButtonsArea>

            </TeamBlockArea>
        </CSSTransition>

    );
    
}

export default TeamBlock;