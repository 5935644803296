import styled from "styled-components";
import playerConverter from "../../../../utils/modelConverters/player";
import Row from "../../Table/Row/Row";
import ButtonsArea from "../../Table/Row/ButtonsArea/ButtonsArea";
import Button from "../../Table/Row/ButtonsArea/Button/Button";

/**
 * Styles
 */

const StyledDiv = styled(Row) `

    &:hover {
        background:#ecf0f1;
        transition:all .3s ease;
    }

    & > div:first-child {
        position:absolute;
        left:1%;
    }

    & > div:nth-child(2) {
        position:absolute;
        left:14%;
    }

    & > div:nth-child(3) {
        position:absolute;
        left:30%;
    }

    & > div:nth-child(4) {
        position:absolute;
        left:85%;
    }

    & span.neo {
        color:#f1c40f;
        font-size:12px;
        padding-right:5px;
        font-weight:bold;
    }

`

/**
 * Component
 * @param {*} props 
 * @returns 
 */
const IllustrativePlayerBlock = (props) => {

    // Handler
    const deleteIllustrativePlayer = (e) => {
        e.stopPropagation();
        props.onDeleteIllustrativePlayer(props.illustrativePlayer.index);
    }

    return (
        <StyledDiv onClick={() => props.onClick(props.illustrativePlayer)}>

            <div>{ props.illustrativePlayer.firstname }</div>
            <div>{ props.illustrativePlayer.name }</div>
            <div><span className="neo">JOUEUR SANS COMPTE</span></div>
            <div className={playerConverter.getFormattedBilling(props.illustrativePlayer.payed).className}>{playerConverter.getFormattedBilling(props.illustrativePlayer.payed).text}</div>

            <ButtonsArea>
                <Button type="error" onClick={deleteIllustrativePlayer} icon="trash" />
            </ButtonsArea>

        </StyledDiv>
    );

}

export default IllustrativePlayerBlock;