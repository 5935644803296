import styled from "styled-components"
import TextInput from "../../../Inputs/TextInput";

const InputArea = styled.div `

    width:300px;
    height:38px;
    
`

const SearchEngine = (props) => {

    return (
        <InputArea>
            <TextInput placeholder={props.placeholder} onChange={props.onChange} />
        </InputArea>
    )

}

export default SearchEngine;