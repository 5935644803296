import styled from "styled-components";
import TourBlock from "./TourBlock/TourBlock";
import { useEffect, useState } from "react";

const StyledDiv = styled.div `

    position:relative;
    top:0;
    left:0;
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;

    & > h4 {
        margin:20px 0;
        font-size:13px;
        font-weight:500;
    }

    & > div {
        display:flex;
        flex-direction:row;
        overflow-x:visible;
    }

`

const TourList = (props) => {

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(props.isLoading);
    }, [props.isLoading]);

    return (

        props.tours &&

        <StyledDiv className={isLoading ? "loading_content" : "loaded_content"}>
            { props.name && <h4>{ props.name }</h4>}
            <div>
                { props.tours.map((t) => <TourBlock tour={t} transparent={props.transparent} key={1} handleOpenTour={props.handleOpenTour} />) }
            </div>
        </StyledDiv>

    )

}

export default TourList;