const moment = require('moment');

const getFormattedDate = (competition) => {
    if(!competition) return "Non chargée";
    const date = moment(competition.dte);
    return date.format('DD/MM/YYYY');
}

const subscriptionModelConverter = {
    getFormattedDate
}

export default subscriptionModelConverter;