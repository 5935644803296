import { useEffect, useState } from "react";
import styled from "styled-components";

/**
 * Styles
 */

const TeamResumeArea = styled.div `

    width:calc(100% - 5px);
    margin:2.5px;
    -webkit-box-shadow: 0px 0px 13px -5px rgba(0,0,0,0.36); 
    box-shadow: 0px 0px 13px -5px rgba(0,0,0,0.36);
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    border-radius:8px;

`

const Container = styled.div `

    display:flex;
    flex-direction:row;
    justify-content:start;
    align-items:center;
    width:100%;
    margin:8px 0;

`

const Ratio = styled.div `

    font-size:28px;
    font-weight:700;
    margin:0 20px;

    &.danger {
        color:#e74c3c;
    }

    &.full {
        color:#2ecc71;
    }

    &.intermediate {
        color:#e67e22;
    }

`

const Legend = styled.div `

    font-size:13px;
    font-weight:500;

`

/**
 * Component
 * @returns 
 */
const TeamResume = (props) => {

    const [nbConfirmed, setNbConfirmed] = useState(0);
    const [nbBilled, setNbBilled] = useState(0);
    const [ratioBilled, setRatioBilled] = useState(1);
    const [ratioConfirmed, setRatioConfirmed] = useState(1);
    const [total, setTotal] = useState(0);

    /**
     * Define the numbers of confirmed and billed teams
     */
    useEffect(() => {

        let billed = 0;
        let confirmed = 0;

        for(const t of props.teams) {
            let payed = true;
            for(const p of t.team.players) {
                if(!p.PlayersTeams.payed) payed = false;
            }
            if(payed) billed++;
            if(t.team.confirmed) confirmed++;
        }

        setNbBilled(billed);
        setNbConfirmed(confirmed);
        setTotal(props.teams.length);

        if(billed / props.teams.length < 0.5) setRatioBilled("danger");
        if(confirmed / props.teams.length < 0.5) setRatioConfirmed("danger");
        if(billed / props.teams.length >= 0.5 && billed / props.teams.length < 1) setRatioBilled("intermediate");
        if(confirmed / props.teams.length >= 0.5 && confirmed / props.teams.length < 1) setRatioConfirmed("intermediate");
        if(billed / props.teams.length === 1) setRatioBilled("full");
        if(confirmed / props.teams.length === 1) setRatioConfirmed("full");


    }, [props.teams]);

    return (

        props.teams &&

        <TeamResumeArea>

            <Container>
                <Ratio className={ ratioConfirmed }>
                    { nbConfirmed }/{ total }
                </Ratio>
                <Legend>
                    confirmées
                </Legend>
            </Container>

            <Container>
                <Ratio className={ ratioBilled }>
                    { nbBilled }/{ total }
                </Ratio>
                <Legend>
                    réglées
                </Legend>
            </Container>

        </TeamResumeArea>

    );
    
}

export default TeamResume;