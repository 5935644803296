import styled from "styled-components";
import Info from "../../Info/Info";
import CompetitionModel from "../../../../utils/modelConverters/competition"

/**
 * Styles
 */

const StyledDiv = styled.div `

    margin:20px 0 0 0;

`

/**
 * Component
 * @param {*} props 
 * @returns 
 */
const CompetitionInfo = (props) => {

    // If no competition provided then show nothing
    if(!props.competition) return;

    const generalInformation = [
        {
            title: "Nom",
            value: props.competition.name
        },
        {
            title: "Date",
            value: CompetitionModel.getFormattedDate(props.competition)
        },
        {
            title: "Sport",
            value: props.competition.sportName
        },
    ];

    const teamInformation = [
        {
            title: "Engagées",
            value: props.competition.nbTeams
        },
        {
            title: "Confirmées",
            value: props.competition.nbConfirmed
        },
        {
            title: "Limite",
            value: CompetitionModel.getFormattedJauge(props.competition)
        },
    ];

    const hubInformation = [
        {
            title: "Inscription",
            value: CompetitionModel.getFormattedOnlineSubscriptionStatus(props.competition).text
        },
        {
            title: "Publication",
            value: CompetitionModel.getFormattedPublicationStatus(props.competition)
        },
        {
            title: "Date de fin",
            value: CompetitionModel.getFormattedLimitOnlineSubDate(props.competition)
        },
    ];
    
    return (
        <StyledDiv>
            <Info title="General" elements={generalInformation} />
            <Info title="Equipes" elements={teamInformation} />
            <Info title="Hub" elements={hubInformation} />
        </StyledDiv>
    );

}

export default CompetitionInfo;