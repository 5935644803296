import { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components"

/**
 * Styles
 */

const skeletonLoading = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 200px 0;
  }
`;

const IndicatorWrapper = styled.div `

    position:relative;
    width:24%;
    height:180px;
    
    border-radius:8px;
    -webkit-box-shadow: 0px 0px 15px -7px rgba(0,0,0,0.6); 
    box-shadow: 0px 0px 15px -7px rgba(0,0,0,0.6);
    border:1px solid #34495e;

    & > div:first-child {
        position:absolute;
        top:0;
        left:0;
    }

    & > div:first-child > h5 {
        color:#f1c40f;
        font-size:100px;
        margin:10px 20px;
    }

    & > div:nth-child(2) {
        position:absolute;
        bottom:0;
        right:0;
    }

    & > div:nth-child(2) > h6 {
        color:#2c3e50;
        font-size:16px;
        margin:10px 20px;
    }

    &.loading {
        background: linear-gradient(90deg, #f5f5f5 25%, #e0e0e0 50%, #f5f5f5 75%);
        background-size: 200% 100%;
        animation: ${skeletonLoading} 1.5s infinite linear;
        color: transparent;
        border-color: #bbb;
        cursor:not-allowed;
    }

    & > div {
        opacity: ${props => (props.isLoading ? 0 : 1)};
        transition: opacity 0.25s ease-in-out;
    }

`

/**
 * Components
 * @param {*} props 
 * @returns 
 */
const Indicator = (props) => {

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(props.isLoading);
    }, [props.isLoading]);

    return (
        <IndicatorWrapper isLoading={isLoading} className={isLoading ? "loading" : null}>
            <div><h5>{ !isLoading ? (props.main ?? "") : null }</h5></div>
            <div><h6>{ !isLoading ? (props.desc ?? "") : null }</h6></div>
        </IndicatorWrapper>
    );
    
}

export default Indicator;