import { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components"

/**
 * Styles
 */

const StyledInput = styled.input `

    border-radius:8px;
    border:0;
    background:${props => props.score ? "white" : "#ecf0f1"};
    padding:0 0 0 8px;
    font-weight:550;
    outline:0;
    width:100%;
    height:100%;
    transition:all .3s ease;

    &:hover {

        transition:all .3s ease;
        background:#d9dadb;

    }

    &.disabled {

        background:grey;

    }

`

/**
 * Component
 * @param {*} props 
 * @returns 
 */
const TextInput = (props) => {

    const [className, setClassName] = useState("");

    useEffect(() => {

        let cn = props.isDisabled ? "disabled " : "";
        cn += props.isLoading ? "loading_box" : "loaded_box"

        setClassName(cn);

    }, [props.isDisabled, props.isLoading]);

    return <StyledInput type="text" value={props.value} placeholder={props.placeholder} id={props.id} onChange={props.onChange} onKeyDown={props.onKeyDown} disabled={ props.isDisabled } className={className} autoCapitalize={props.autoCapitalize} score={props.score} />
}

export default TextInput;