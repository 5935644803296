import styled from "styled-components";
import { useEffect, useState } from "react";
import CompetitionList from "../../components/Software/CompetitionList/CompetitionList";
import CompetitionInfo from "../../components/Software/CompetitionList/CompetitionInfo/CompetitionInfo";
import { useNavigate } from "react-router-dom";
import fetchAPI from "../../utils/fetch";
import Wrapper from "../../components/Software/Wrapper/Wrapper";
import HeaderWrapper from "../../components/Software/Wrapper/HeaderWrapper/HeaderWrapper";
import LargeButton from "../../components/Software/LargeButton/LargeButton";
import { useCookies } from "react-cookie";

/**
 * Styles
 */

const Cols = styled.div `

    position:relative;
    top:0;
    left:0;
    width:100%;
    height:85%;
    display:flex;
    flex-direction:row;
    justify-content:space-between;

`

const Col1 = styled.div `

    position:relative;
    top:0;
    left:0;
    width:80%;

`

const Col2 = styled.div `

    position:relative;
    top:0;
    left:0;
    width:17%;
    display:flex;
    justify-content:start;
    align-items:start;
    flex-direction:column;
    overflow-y:auto;

`

/**
 * Component
 * @returns 
 */
const Competitions = () => {

    const navigate = useNavigate();
    const [competitions, setCompetitions] = useState([]);
    const [, setCookies] = useCookies(['competition', 'proAuthorization']);
    const [competitionsRestriction, setCompetitionsRestriction] = useState(null);
    const [competition, setCompetition] = useState(null);
    const [error, setError] = useState(null);
    const [fetchTrigger, setFetchTriger] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    /**
     * By default, delete cookie of selected competition
     */
    useEffect(() => {
        setCookies("competition", null, {path: '/'});
    }, [setCookies]);

    /**
     * Get competitions
     */
    useEffect(() => {

        const fetchCompetitions = async () => {

            try {

                setIsLoading(true);

                const {data, error} = await fetchAPI("GET", "/competition/getall/pro", null);

                if(error) {
                    navigate('/e');
                    return;
                }

                setCompetitions(data && data.competitions ? data.competitions : []);
                setCompetition(data && data.competitions && data.competitions[0] ? data.competitions[0] : null);
                setIsLoading(false);

            }
            catch(e) {
                navigate('/e');
            }

        }

        fetchCompetitions();

    }, [fetchTrigger, navigate]);

    /**
     * Handler for search
     * @param {*} e 
     */
    const handleSearch = (e) => {

        const search = e.target.value;

        if(search !== null && search.length !== 0) {

            setCompetitionsRestriction(competitions.filter((c) => {
                return c.name.toLowerCase().includes(search.toLowerCase());
            }));
        
        } else {
            setCompetitionsRestriction(null);
        }

    }

    /**
     * Btn add competition
     * @param {*} e 
     */
    const handleRedirectToAddPage = (e) => {
        e.preventDefault();
        navigate("/add-competition");
    }

    /**
     * Change selected competition
     * @param {*} id 
     */
    const handleChangeSelected = (id) => {
        competitions.forEach((c) => {
            if(c.competitionId === id) setCompetition(c);
        });
    }

    /**
     * Delete handler
     * @param {*} event 
     * @param {*} id 
     * @returns 
     */
    const handleDelete = async (event, id) => {

        event.stopPropagation();

        const cf = window.confirm("Souhaitez-vous vraiment supprimer cette compétition ?");

        if(cf) {

            try {

                setIsLoading(true);

                const {error} = await fetchAPI('DELETE', '/competition/delete/pro', {
                    competitionId: id
                });

                if(error) {
                    if(error === "ERR_LINKED_TOURS") throw new Error("Des tours sont liés à cette compétition.");
                    if(error === "ERR_LINKED_FIELDS") throw new Error("Des terrains sont liés à cette compétition.");
                    if(error === "ERR_LINKED_LEVELS") throw new Error("Des niveaux sont liés à cette compétition.");
                    navigate('/e');
                }

                setFetchTriger(fetchTrigger + 1);

            }
            catch(e) {

                if(e.message.includes('compétition')) {
                    setIsLoading(false);
                    setError(e.message);
                    return;
                }

                navigate('/e');

            }

        }

    }

    return (

        competitions &&

        <Wrapper popup={[error]} isLoading={isLoading}>

            <HeaderWrapper title="Ouvrir une compétition" showSearchEngine={true} searchEnginePlaceholder="Rechercher des compétitions ..." placeholderOnChange={handleSearch} />

            <Cols>

                <Col1>
                    <CompetitionList competitions={competitionsRestriction ?? competitions } onHoverElement={handleChangeSelected} deleteCompetition={handleDelete} />
                </Col1>

                <Col2>
                    <LargeButton message1="Nouvelle" message2="compétition" callback={handleRedirectToAddPage} />
                    <CompetitionInfo competition={competition} />
                </Col2>

            </Cols>
           
        </Wrapper>

    );

}

export default Competitions;