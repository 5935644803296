import styled from "styled-components";
import PouleBlock from "./PouleBlock/PouleBlock";
import { useEffect, useState } from "react";

const StyledDiv = styled.div `

    position:relative;
    width:calc(100% - 60px);
    overflow-x:auto;
    overflow-y:hidden;
    margin:0 30px;
    padding:5px 0 10px 0;
    display:flex;
    flex-direction:row;
    
    &::-webkit-scrollbar {
        height: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0.05);
        border-radius: 10px;
    }

`

const PouleList = (props) => {

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(props.isLoading);
    }, [props.isLoading])

    return (

        <StyledDiv className={isLoading ? "loading_content" : "loaded_content"}>
            { props.poules.map((p) => <PouleBlock poule={p} key={p.pouleId} openPoule={props.openPoule} isFinal={props.isFinal} />) }
        </StyledDiv>

    );

}

export default PouleList;