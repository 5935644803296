import styled from "styled-components";
import TourList from "./TourList/TourList";
import PouleList from "./PouleList/PouleList";
import ValidationSection from "../ValidationSection/ValidationSection";

const DerivationSectionArea = styled.div `

    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background:rgba(0,0,0,0.3);
    z-index:9999;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;

`

const Container = styled.div `

    position:relative;
    width:900px;
    height:700px;
    background:white;
    border-radius:8px;
    box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.17);
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    padding:30px;

`

const CloseButton = styled.div  `

    position:absolute;
    top:0;
    right:0;
    color:grey;
    font-size:35px;
    margin:10px;
    cursor:pointer;
    transition:all .3s ease;

    &:hover {
        transition:all .3s ease;
        color:#e74c3c;
    }

`

const Title = styled.h4  `

    font-size:20px;
    margin:0;

`

const Subtitle = styled.h5  `

    color:#f1c40f;
    font-size:16px;
    margin:15px 0;
    
`

const DerivationSection = (props) => {

    return (

        <DerivationSectionArea>

            <Container>

                <CloseButton className="close" onClick={props.handleCloseDerivate}><ion-icon name="close"></ion-icon></CloseButton>

                <div>

                    <Title>Engager l'équipe { props.team.name } dans la compétition</Title>

                    <Subtitle>1/ Choisir un tour</Subtitle>
                    <TourList selectedDerivationTourId={props.selectedDerivationTourId} handleChangeSelectedDerivationTourId={props.handleChangeSelectedDerivationTourId} tours={props.tours} />

                    {
                        props.poules && props.selectedDerivationTourId &&
                        <>
                            <Subtitle>2/ Choisir une poule</Subtitle>
                            <PouleList selectedDerivationPouleId={props.selectedDerivationPouleId} handleChangeSelectedDerivationPouleId={props.handleChangeSelectedDerivationPouleId} poules={props.poules} />
                        </>
                    }

                </div>

                <ValidationSection icon="git-branch-outline" message1="Engager" message2="l'équipe" callback={props.handleSubmitDerivation} />

            </Container>

        </DerivationSectionArea>
    )
}

export default DerivationSection;