import styled from "styled-components";
import TeamModel from "../../../../utils/modelConverters/team"
import Info from "../../Info/Info";

/**
 * Styles
 */

const TeamInfoArea = styled.div `

    margin:20px 0 0 0;

`

/**
 * Component
 * @param {*} props 
 * @returns 
 */
const TeamInfo = (props) => {

    const registerInformation = [
        {
            title: "Canal",
            value: TeamModel.getFormattedCanal(props.team),
        },
        {
            title: "Date",
            value: TeamModel.getFormattedRegistrationDate(props.team)
        },
        {
            title: "Paiement",
            value: TeamModel.getFormattedPaymentCanal(props.team),
        },
    ];

    const teamInformation = [
        {
            title: "Equipe",
            value: props.team.name
        },
        {
            title: "Niveau",
            value: props.team.level.name
        },
        {
            title: "Joueurs",
            value: props.team.players.length,
        },
    ];

    return (

        <TeamInfoArea>
            <Info title="Inscription" elements={registerInformation} />
            <Info title="Equipe" elements={teamInformation} />
        </TeamInfoArea>

    );
    
}

export default TeamInfo;