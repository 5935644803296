import styled from "styled-components";

/**
 * Styles
 */

const InfoArea = styled.div `

    margin:0 0 30px 0;
    font-size:14px;

`

const InfoTitle = styled.h4 `

    margin:10px 0;
    font-weight:600;
    font-size:16px;
    
`

const Elements = styled.p `

    margin:10px 0;
    line-height:20px;
    color:#7f8c8d;
    
`

const TitleKey = styled.span `

    color:#2c3e50;
    font-weight:600;

`

/**
 * Component
 * @param {*} props 
 * @returns 
 */
const Info = (props) => {
    
    return (

        <InfoArea>

            <InfoTitle>{props.title}</InfoTitle>

            <Elements>
                { props.elements.map((el,i) => (
                    <span key={i}><TitleKey>{ el.title }: </TitleKey> { el.value }<br /></span>
                ))}
            </Elements>

        </InfoArea>

    );
    
}

export default Info;