import styled from "styled-components";
import Links from "./Links/Links";
import Profile from "./Profile/Profile";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import fetchAPI from "../../../../../utils/fetch";

/**
 * Styles
 */

const MenuWrapper = styled.div `

    display:flex;
    justify-content:space-between;
    align-items:center;

`

/**
 * Component
 * @returns 
 */
const Menu = () => {

    const navigate = useNavigate();
    const [cookies] = useCookies(["proAuthorization"]);
    const [pro, setPro] = useState(null);
    const [subscription, setSubscription] = useState(null);

    useEffect(() => {

        const fetchPro = async () => {

            try {

                const {data, error} = await fetchAPI('GET', '/pro/get/pro', {});

                if(error) navigate('/login');

                setPro(data.pro);
                setSubscription(data.subscription);

            }
            catch(e) {
                navigate('/login');
            }

        }

        fetchPro();

    }, [cookies.proAuthorization, navigate]);

    return (
        <MenuWrapper>
            <Links />
            <Profile pro={pro} subscription={subscription} />
        </MenuWrapper>
    )

}

export default Menu;