import styled from "styled-components";
import ValidationSection from "../../components/Software/ValidationSection/ValidationSection";
import TextInput from "../../components/Software/Inputs/TextInput";
import { useEffect, useState } from "react";
import SelectInput from "../../components/Software/Inputs/SelectInput";
import CheckboxInput from "../../components/Software/Inputs/CheckboxInput";
import PlayerList from "../../components/Software/PlayerList/PlayerList";
import { useNavigate, useParams } from "react-router-dom";
import teamValidator from "../../utils/validators/team";
import Wrapper from "../../components/Software/Wrapper/Wrapper";
import Section from "../../components/Software/Section/Section";
import FormBlock from "../../components/Software/FormBlock/FormBlock";
import fetchAPI from "../../utils/fetch";
import { useCookies } from "react-cookie";
import { parseDate } from "../../utils/validators/player";
import HeaderWrapper from "../../components/Software/Wrapper/HeaderWrapper/HeaderWrapper";

/**
 * Styles
 */

const Cols = styled.div `

    position:relative;
    top:0;
    left:0;
    width:100%;
    height:85%;
    display:flex;
    flex-direction:row;
    justify-content:space-between;

`

const Col1 = styled.div `

    position:relative;
    top:0;
    left:0;
    width:80%;

`

const Col2 = styled.div `

    position:relative;
    top:0;
    left:0;
    width:17%;
    display:flex;
    justify-content:start;
    align-items:start;
    flex-direction:column;
    overflow-y:auto;

`

const TeamNameBlock = styled.div `

    width:300px;
    height:32px;

`

const TeamLevelBlock = styled.div `

    width:auto;
    height:32px;

`

const TeamForfaitBlock = styled.div `

    width:34px;
    height:34px;
    margin:0 15px 0 0;

`

const TeamConfirmationBlock = styled.div `

    width:34px;
    height:34px;
    margin:0 15px 0 0;

`

/**
 * Component
 * @param {*} param0 
 * @returns 
 */
const Team = ({ match }) => {

    const { id } = useParams();
    const navigate = useNavigate();
    const [cookies] = useCookies(['competition']);
    const [error, setError] = useState(null);
    const [teamName, setTeamName] = useState("");
    const [teamLevelId, setTeamLevelId] = useState(0);
    const [teamGiveup, setTeamGiveUp] = useState(false);
    const [teamConfirmed, setTeamConfirmed] = useState(false);
    const [players, setPlayers] = useState([]);
    const [illustrativePlayers, setIllustrativePlayers] = useState([]);
    const [levels, setLevels] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    /**
     * Load team from database
     */
    useEffect(() => {

        // Return if id of team is not specified
        if(!id) return;

        const fetchTeam = async () => {

            try {

                setIsLoading(true);

                // Fetch API
                const {data, error} = await fetchAPI("GET", "/team/get/pro", {
                    teamId: parseInt(id)
                });

                if(error) throw new Error();

                // Set team info
                setTeamName(data && data.team ? data.team.name : "");
                setTeamLevelId(data && data.team ? data.team.levelId : 0);
                setTeamGiveUp(data && data.team ? data.team.giveup : false);

                // Set players
                setPlayers(data.team.players.map((p, i) => {
                    return {
                        firstname: p.firstname,
                        name: p.name,
                        email: p.email,
                        phone: p.phone,
                        playerId: p.playerId,
                        payed: p.PlayersTeams.payed,
                        birthDate: p.birthDate,
                        updateIndex: i
                    }
                }));

                // Set Illustrative players
                setIllustrativePlayers(data.team.illustrativePlayers.map((ip, i) => {
                    return {
                        firstname: ip.firstname,
                        name: ip.name,
                        index: i+1,
                        payed: ip.IllustrativePlayersTeams.payed
                    }
                }));

                setIsLoading(false);

            }
            catch(e) {
                
                navigate('/e');
                return;

            }


        }

        fetchTeam();

    }, [id, navigate])

    /**
     * Load levels from database
     */
    useEffect(() => {

        const fetchLevels = async () => {

            try {

                setIsLoading(true);

                // Fetch API
                const {data, error} = await fetchAPI("GET", "/level/getallofcompetition/pro", {
                    competitionId: parseInt(cookies.competition)
                });
                
                if(error) throw new Error();

                // Set levels
                setLevels(data && data.levels ? data.levels.map((l) => {
                    return {
                        value: l.levelId,
                        text: l.name
                    }
                }) : []);

                setIsLoading(false);

            }
            catch(e) {
                
                navigate('/e');
                return;

            }

        }

        fetchLevels();

    }, [cookies.competition, navigate]);

    // Handlers

    const handleChangeTeamName = (e) => {setTeamName(e.target.value); setError(null); };
    const handleChangeTeamLevelId = (e) => {setTeamLevelId(e.target.value); setError(null); };
    const handleChangeGiveUp = (g) => {setTeamGiveUp(g); setError(null); };
    const handleChangeConfirm = (c) => {setTeamConfirmed(c); setError(null); };

    /**
     * Delete player handler
     * @param {*} email 
     */
    const handleDeletePlayer = (email) => {
        const cf = window.confirm("Voulez-vous vraiment supprimer ce joueur ?");
        if(cf) setPlayers(players.filter((p) => p.email !== email));
    }

    /**
     * Delete illustrative player handler
     * @param {*} index 
     */
    const handleDeleteIllustrativePlayer = (index) => {
        const cf = window.confirm("Voulez-vous vraiment supprimer ce joueur ?");
        if(cf) setIllustrativePlayers(illustrativePlayers.filter((p) => p.index !== index));
    }

    /**
     * Post team handler
     */
    const handleSubmitForm = async () => {

        setIsLoading(true);
        
        try {

            // Try to validate the team
            teamValidator(teamName, teamLevelId, teamGiveup, teamConfirmed);

            // Initalize empty structures to stack players, players to create and illustrative players
            let pl = [];
            let pltc = [];
            let ip = [];

            // Iterate on each players
            for(const p of players) {
                if(p.playerId) {
                    pl.push({
                        id: p.playerId,
                        payed: p.payed
                    });
                }
                else {
                    pltc.push({
                        firstname: p.firstname,
                        name: p.name,
                        email: p.email,
                        birthDate: p.birthDate,
                        phone: p.phone,
                        payed: p.payed
                    })
                }
            }

            // Iterate on each illustrative players
            for(const ip_ of illustrativePlayers) {
                ip.push({
                    firstname: ip_.firstname,
                    name: ip_.name,
                    payed: ip_.payed
                })
            }

            let method = "POST";
            let endpoint = "engage";

            let team = {
                name: teamName,
                levelId: teamLevelId,
                players: pl,
                playersToCreate: pltc,
                illustrativePlayers: ip,
                giveup: teamGiveup,
                confirmed: teamConfirmed
            }

            if(id) {
                method = "PUT"
                endpoint = "update"
                team = {
                    ...team,
                    teamId: id
                }
            }

            const {error} = await fetchAPI(method, '/team/' + endpoint + '/pro', {
                team: team
            });

            if(error && error === "ERR_ALREADY_IN_TEAM") {
                setError("Un ou plusieurs joueurs sont déja engagés dans une équipe.");
                return;
            }
            if(error) throw new Error();

            navigate('/teams', {
                state: {
                    'success': 'Votre équipe a été créée/modifiée'
                }
            });
        
        }
        catch(e) {
            
            navigate('/e');
            return;

        }

    }

    /**
     * Handler for adding illustrative player
     * @param {*} firstname 
     * @param {*} name 
     * @returns 
     */
    const handleAddIllustrativePlayer = (illustrativePlayer) => {

        // Check if there is a match
        const match = illustrativePlayer.updateIndex !== -1;

        // If there is a match map to edit 
        if(match) {
            setIllustrativePlayers(illustrativePlayers.map((ip) => {
                if(ip.index !== illustrativePlayer.updateIndex) return ip;
                return {
                    firstname: illustrativePlayer.firstname,
                    name: illustrativePlayer.name,
                    payed: illustrativePlayer.payed,
                    index: ip.index
                }
            }));
            return;
        }

        // Else insert new IP
        setIllustrativePlayers([...illustrativePlayers, {
            index: illustrativePlayers.length + 1,
            firstname: illustrativePlayer.firstname,
            name: illustrativePlayer.name,
            payed: illustrativePlayer.payed
        }]);

    }

    /**
     * Add player handler
     * @param {*} player
     * @returns 
     */
    const handleAddPlayer = async (player) => {

        // Check if 
        const in_ = players.filter((p) => p.updateIndex === player.updateIndex || p.playerId === player.playerId);

        // If player is in team
        if(in_.length === 0) {

            let newPlayer = {
                firstname: player.firstname,
                name: player.name,
                email: player.email,
                birthDate: parseDate(player.birthDate),
                phone: player.phone,
                playerId: player.playerId ? player.playerId : 0,
                payed: player.payed,
                updateIndex: players.length
            }

            // If the player is already in database
            if(newPlayer.playerId === 0) {

                // Check if player exists
                const {data, error} = await fetchAPI('GET', '/player/getbycriteria/pro', {
                    criteria: player.email
                });

                // Return if error
                if(error) return;

                // If player exists fill with player id
                if(data.player) newPlayer.playerId = data.player.playerId;

            }

            setPlayers([...players, newPlayer]);

            return;

        }

        // Player already in team without player id, and player added without playerid
        if(in_.length === 1 && !in_[0].playerId && !player.playerId) {
            setPlayers(players.map((p) => {
                if(p.updateIndex === player.updateIndex) {
                    return {
                        firstname: player.firstname,
                        name: player.name,
                        email: player.email,
                        phone: player.phone,
                        birthDate: parseDate(player.birthDate),
                        playerId: 0,
                        payed: player.payed,
                        updateIndex: player.updateIndex
                    }
                }
                return p;
            }));
            return;
        }

        // Player already in team and have a player id
        if(in_.length === 1 && in_[0].playerId && player.playerId) {
            setPlayers(players.map((p) => {
                if(p.playerId !== player.playerId) return p;
                return {
                    ...p,
                    payed: player.payed
                }
            }));
            return;
        }

    }

    return (

        <Wrapper isLoading={isLoading} popup={[error]}>

            <HeaderWrapper title={id ? "Modifier l'équipe" : "Ajouter une équipe"} showSearchEngine={false} />
            
            <Cols>
            
                <Col1>

                    <Section align="start">

                        <FormBlock>
                            <label>Nom</label>
                            <TeamNameBlock>
                                <TextInput placeholder="Nom de l'équipe" value={teamName} onChange={handleChangeTeamName} isLoading={isLoading} />
                            </TeamNameBlock>
                        </FormBlock>

                        <FormBlock>
                            <label>Niveau</label>
                            <TeamLevelBlock>
                                <SelectInput options={levels} value={teamLevelId} onChange={handleChangeTeamLevelId} isLoading={isLoading} />
                            </TeamLevelBlock>
                        </FormBlock>

                        </Section>

                        <Section title="Status de l'équipe" align="start">

                        <FormBlock>
                            <TeamForfaitBlock>
                                <CheckboxInput value={teamGiveup} onChange={handleChangeGiveUp} isLoading={isLoading} />      
                            </TeamForfaitBlock>
                            <label>Equipe forfait</label>
                        </FormBlock>

                        <FormBlock>
                            <TeamConfirmationBlock>
                                <CheckboxInput value={teamConfirmed} onChange={handleChangeConfirm} isLoading={isLoading} />      
                            </TeamConfirmationBlock>
                            <label>Equipe confirmée</label>
                        </FormBlock>

                        </Section>

                        <Section title="Composition">
                        <PlayerList illustrativePlayers={illustrativePlayers} players={players} onAddIllustrativePlayer={handleAddIllustrativePlayer} onDeleteIllustrativePlayer={handleDeleteIllustrativePlayer} onAddPlayer={handleAddPlayer} onDeletePlayer={handleDeletePlayer} />
                        
                    </Section>

                    <ValidationSection icon="save" message1="Enregistrer" message2="l'équipe" callback={handleSubmitForm} />

                </Col1>

                <Col2>

                </Col2>
            
            </Cols>


        </Wrapper>
    );

}

export default Team;