import Area from "../components/Authentication/Area/Area";

/**
 * Component
 * @returns 
 */
const Authentication = (props) => {
   
    return (
        <Area page={props.page} />
    );
}

export default Authentication;