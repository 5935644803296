import { useEffect, useState } from "react";
import styled from "styled-components";

const StyledDiv = styled.div `

    position:relative;
    top:0;
    left:0;
    width:100%;
    display:flex;
    margin:8px 0;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    font-size:60px;
    color:#bdc3c7;

    & > p {
        margin:0;
        padding:0;
        line-height:10px;
        font-size:40px;
        display:flex;
        flex-direction:column;
        height:40px;
    }

`

const Arrow = (props) => {

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(props.isLoading);
    }, [props.isLoading]);

    return (

        <StyledDiv className={isLoading ? "loading_content" : "loaded_content"}>

            {

            props.tpe === "down" ?
            
            <ion-icon name="arrow-down-outline"></ion-icon>

            :

            <p><span>.</span><span>.</span><span>.</span></p>

            }
            
        </StyledDiv>

    )

}

export default Arrow;