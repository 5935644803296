import styled from "styled-components"
import SearchEngine from "./SearchEngine/SearchEngine"
import TitleWrapper from "./TitleWrapper/TitleWrapper"

const HeaderWrapperArea = styled.div `

    position:relative;
    top:0;
    left:0;
    width:80%;
    display:flex;
    justify-content:space-between;
    align-items:center;
    height:100px;

`

const HeaderWrapper = (props) => {

    return (
        <HeaderWrapperArea>
            <TitleWrapper title={props.title} />
            { props.showSearchEngine && <SearchEngine placeholder={props.searchEnginePlaceholder} onChange={props.placeholderOnChange} /> }
        </HeaderWrapperArea>
    )

}

export default HeaderWrapper;