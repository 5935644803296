import styled from "styled-components";

const StyledDiv = styled.div `

    position:relative;
    top:0;
    left:0;
    width:80%;
    margin-right:20px;


`

const InfoAccount = (props) => {

    return <StyledDiv>{props.children}</StyledDiv>

}

export default InfoAccount;