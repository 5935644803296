import styled from 'styled-components';
import { darken } from 'polished';
import TextInput from "../../Inputs/TextInput";

const ScoreDiv = styled.div `
    width:32px;
    height:32px;
    margin:5px;
`

const StyledDiv = styled.div `

    position:relative;
    top:0;
    left:0;
    width:260px;
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    height:140px;
    margin:8px;
    background:#ecf0f1;
    display:inline-flex;
    border-radius:8px;
    box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.17);

`

const Button = styled.div `

    height:30px;
    text-align:center;
    display:flex;
    width:100%;
    justify-content:center;
    align-items:center;
    font-size:12px;
    font-weight:bold;
    color:white;
    background:${props => props.background};
    cursor:pointer;
    transition:all .3s ease;

    &:hover {
        background: ${props => darken(0.2, props.background)};
        transition:all .3s ease;
    }

    & > ion-icon {
        font-size:18px;
        padding:0 8px 0 0;
    }

`

const ContentGame = styled.div `

    width:100%;
    border-left-top-radius:8px;
    border-left-bottom-radius:8px;

`

const Actions = styled.div `

    width:100%;
    border-bottom-left-radius:8px;
    border-bottom-right-radius:8px;
    overflow:hidden;
    display:flex;

`

const Teams = styled.div `

    display:flex;
    align-items:center;
    justify-content:center;
    text-align:center;
    font-weight:bold;
    padding:10px;
    font-size:15px;

    & > span {
        color:#f1c40f;
        font-size:12px;
        padding:0 5px;
    }

`

const Scores = styled.div `

    display:flex;
    align-items:center;
    justify-content:center;
    text-align:center;

`

const ScoreBox = styled.div `

    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    margin:0 5px;

`

const TypeScoreLabel = styled.label `

    font-size:10px;
    padding:0 0 10px 0;

`

const GameBlock = (props) => {

    if(props.steps.length !== props.scores['scoresTeamA'].length) return;

    const stepsSorted = props.steps.sort((a, b) => {
        if(a.CompetitionsSteps.index < b.CompetitionsSteps.index) return 1;
        if(a.CompetitionsSteps.index > b.CompetitionsSteps.index) return -1;
        return 0;
    }).map((s) => {
        return s.name.charAt(0).toUpperCase() + s.name.slice(1) + "s";
    });

    let winner = null;

    for(let i = 0; i < props.steps.length; i++) {
        if(parseInt(props.scores['scoresTeamA'][i]) > parseInt(props.scores['scoresTeamB'][i])) winner = 'A';
        if(parseInt(props.scores['scoresTeamA'][i]) < parseInt(props.scores['scoresTeamB'][i])) winner = 'B';
        if(winner) break;
    }

    let filled = false;

    for(let i = 0; i < props.steps.length; i++) {
        if((props.scores['scoresTeamA'][i] !== 0 && props.scores['scoresTeamA'][i] !== '' && props.scores['scoresTeamA'][i] !== '0') || (props.scores['scoresTeamB'][i] !== 0 && props.scores['scoresTeamB'][i] !== '' && props.scores['scoresTeamB'][i] !== '0')) filled = true;
    }
    
    
    return (

        <StyledDiv>

            <ContentGame>

                <Teams>{ props.game.teamAName } <span>vs</span> { props.game.teamBName }</Teams>

                <Scores>

                { props.scores['scoresTeamA'].map((s, i) => (

                    <ScoreBox>
                        <ScoreDiv>
                            <TextInput value={s ? s : ''} centered={true} onChange={ (e) => props.onUpdateGame(e, 'scoresTeamA', i, props.game.gameId) } score={true} />
                        </ScoreDiv>
                        <TypeScoreLabel>{stepsSorted[i]}</TypeScoreLabel>
                    </ScoreBox>

                ))}

                |

                { props.scores['scoresTeamB'].map((s, i) => (

                    <ScoreBox>
                        <ScoreDiv>
                            <TextInput value={s ? s : ''} centered={true} onChange={ (e) => props.onUpdateGame(e, 'scoresTeamB', i, props.game.gameId) } score={true} />
                        </ScoreDiv>
                        <TypeScoreLabel>{stepsSorted[i]}</TypeScoreLabel>
                    </ScoreBox>

                ))}

                </Scores>


            </ContentGame>  

            <Actions>

                {
                    filled ?
                        <Button background="#2ecc71" onClick={() => props.onValidateGameUpdates(props.game.gameId)}><ion-icon name="checkmark-circle-outline"></ion-icon>Sauvegarder</Button>
                    :
                        <Button background="#e74c3c" onClick={() => props.onValidateGameUpdates(props.game.gameId)}><ion-icon name="checkmark-circle-outline"></ion-icon>Résultat à saisir</Button>
                }
                { props.isFinal && <Button background="#3498db" onClick={() => props.generatePdfFinal(props.game.teamAName, props.game.teamBName)}><ion-icon name="print-outline"></ion-icon>Imprimer</Button> }

            </Actions> 

        </StyledDiv>

    );

}

export default GameBlock;