import styled from "styled-components"

const ButtonsAreaBox = styled.div `

    display:flex;
    position:absolute;
    right:0.5%;
    justify-content:end;
    align-items:center;
    padding-right:5px;
    
`

const ButtonsArea = (props) => {

    return <ButtonsAreaBox>{ props.children }</ButtonsAreaBox>

}

export default ButtonsArea;