import styled from "styled-components";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import fetchAPI from "../../utils/fetch";
import Wrapper from "../../components/Software/Wrapper/Wrapper";
import HeaderWrapper from "../../components/Software/Wrapper/HeaderWrapper/HeaderWrapper";
import InfoAccount from "../../components/Software/InfoAccount/InfoAccount";
import LicenseInfo from "../../components/Software/InfoAccount/LicenseInfo/LicenseInfo";
import PersonalInfo from "../../components/Software/InfoAccount/PersonalInfo/PersonalInfo";
import ImageAccount from "../../components/Software/ImageAccount/ImageAccount";
import { useCookies } from "react-cookie";

/**
 * Styles
 */

const Ct = styled.div `

    position:relative;
    top:0;
    left:0;
    width:80%;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;

`

const ModificationRequest = styled.p `

    position:relative;
    top:0;
    left:0;

`

const StripeAccount = styled.div `

    position:relative;
    top:0;
    color:#635AFF;
    padding:6px 0;
    font-weight:bold;
    cursor:pointer;

`

/**
 * Component
 * @returns
 */
const Account = () => {

    const navigate = useNavigate();
    const [cookies, setCookies] = useCookies();
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [pro, setPro] = useState(null);
    const [subscription, setSubscription] = useState(null);

    /**
     * Fetch pro
     */
    useEffect(() => {

        const fetchPro = async () => {

            setIsLoading(true);

            try {

                const {data, error} = await fetchAPI('GET', '/pro/get/pro', {});

                if(error) navigate('/e');
                setPro(data.pro);
                setSubscription(data.subscription);
                setIsLoading(false);

            }
            catch(e) {
                navigate('/e');
            }

        }

        fetchPro();

    }, [cookies.proAuthorization, navigate]);

    const requestStripeAccount = async () => {

        try {

            setIsLoading(true);
            const {data, error} = await fetchAPI('GET', '/pro/askstripeaccount/pro');
            if(error) throw new Error();

            window.location.replace(data.createAccountUrl);

        }
        catch(e) {
            setError("Une erreur est survenue lors de la demande de compte Stripe");
            setIsLoading(false);
            return;
        }

    }

    const getAccessToStripe = async () => {

        try {

            setIsLoading(true);

            const {data, error} = await fetchAPI('GET', '/pro/askstripelogin/pro');

            if(error) throw new Error();

            window.location.replace(data.loginLink.url);

        }
        catch(e) {
            setError("Une erreur est survenue lors de la demande de lien de connexion au compte Stripe");
            setIsLoading(false);
        }

    }

    return (

        <Wrapper popup={[error]} isLoading={isLoading}>

            <HeaderWrapper title="Mon compte organisateur Neoscore" showSearchEngine={false} />

            <Ct>

                <InfoAccount>
                    <LicenseInfo subscription={subscription} isLoading={isLoading} />
                    <PersonalInfo pro={pro} isLoading={isLoading} />
                </InfoAccount>

                <ImageAccount pro={pro} isLoading={isLoading} />

            </Ct>

            <ModificationRequest className={ isLoading ? "loading_content" : "loaded_content" }>Pour modifier vos paramètres d'organisation, merci de contacter notre équipe technique.</ModificationRequest>

            { pro && pro.stripeStatus === 0 && <StripeAccount className={isLoading ? "loading_content" : "loaded_content"} onClick={requestStripeAccount}>Créez votre compte Stripe pour recevoir des paiements</StripeAccount> }
            { pro && pro.stripeStatus === 1 && <StripeAccount className={isLoading ? "loading_content" : "loaded_content"} onClick={requestStripeAccount}>Complétez la création de votre compte Stripe pour pouvoir recevoir des paiements</StripeAccount> }
            { pro && pro.stripeStatus === 2 && <StripeAccount className={isLoading ? "loading_content" : "loaded_content"} onClick={getAccessToStripe}>Accédez à votre espace Stripe</StripeAccount> }

        </Wrapper>

    );

}

export default Account;