import styled from "styled-components";

const RowArea = styled.div `

    position:relative;
    height:54px;
    flex-shrink: 0;
    width:calc(100% - 10px);
    border-radius:8px;
    margin:10px 5px;
    -webkit-box-shadow: 0px 0px 13px -5px rgba(0,0,0,0.46); 
    box-shadow: 0px 0px 13px -5px rgba(0,0,0,0.46);
    display:flex;
    justify-content:space-between;
    line-height:54px;
    font-size:14px;
    font-weight:500;
    cursor:pointer;
    transition:all .3s ease;
    align-items:center;

    &:hover {
        background:#ecf0f1;
        transition:all .3s ease;
    }

    & > div:first-child {
        font-weight:700;
    }

    &.fade-enter {
        opacity: 0;
        transform: translateY(-10px);
    }

    &.fade-enter-active {
        opacity: 1;
        transform: translateY(0);
        transition: opacity 300ms, transform 300ms;
    }

    &.fade-exit {
        opacity: 1;
        transform: translateY(0);
    }
        
    &.fade-exit-active {
        opacity: 0;
        transform: translateY(-10px);
        transition: opacity 300ms, transform 300ms;
    }

`

const Row = (props) => {

    return <RowArea {...props}>{ props.children }</RowArea>

}

export default Row;