import styled from "styled-components";
import LevelBlock from "./LevelBlock/LevelBlock";
import EmptyList from "../EmptyList/EmptyList";
import { useEffect, useState } from "react";

/**
 * Styles
 */

const LevelListWrapper = styled.div `

    position:relative;
    display:flex;
    flex-direction:column;
    top:0;
    left:0;
    width:100%;
    border-collapse: collapse;
    overflow-y:auto;
    height:100%;
    border-spacing:0 10px;

    & > thead > tr > td {
        text-transform:uppercase;
        font-style:italic;
        font-size:12px;
        font-weight:700;
        color:grey;
    }

    & > div {
        opacity: ${props => (props.isLoading ? 0 : 1)};
        transition: opacity 0.25s ease-in-out;
    }

`

/**
 * Component
 * @param {*} props 
 * @returns 
 */
const LevelList = (props) => {

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(props.isLoading);
    }, [props.isLoading])

    return (

        props.levels && props.deleteLevel &&

        <>
            {props.levels.length === 0 ? <EmptyList link={{path: '/add-level', text: "Ajoutez votre premier niveau"}} /> : null}
            
            <LevelListWrapper isLoading={isLoading}>
                { props.levels.map((level) => (
                    <LevelBlock key={level.levelId} level={level} deleteLevel={props.deleteLevel} />
                )) }
            </LevelListWrapper>

        </>

    );
    
}

export default LevelList;