import styled from "styled-components";
import subscriptionModelConverter from "../../../../utils/modelConverters/subscription";

const StyledDiv = styled.div `

    position:relative;
    top:0;
    left:0;
    width:100%;
    margin:20px 0;
    background:#ecf0f1;
    padding:10px 20px 20px 20px;

`

const LicenseTitle = styled.h2 `

    font-size:26px;
    font-weight:bold;
    font-size:20px;
    color:#2ecc71;
    text-transform:uppercase;
    font-size:16px;
    margin:10px 0;

    & > span {
    
        display:block;
        color:black;
        font-size:12px;
        text-transform:none;
        padding:5px 0;
    
    }

`

const Counters = styled.div `

    position:relative;
    top:0;
    left:0;
    width:100%;

`

const StyledLink = styled.a `

    padding:10px 0;
    color:rgba(243, 156, 18,1.0);

`

const LicenseInfo = (props) => {

    return (

        <StyledDiv className={ props.isLoading ? "loading_content" : "loaded_content" }>

            <LicenseTitle>Produit activé<span>Une licence est associée à ce compte Neoscore</span></LicenseTitle>

            {
                props.subscription &&
                <Counters>
                    <b>Edition :</b> Digimeo Neoscore { props.subscription.name.charAt(0).toUpperCase() + props.subscription.name.slice(1) } Edition<br />
                    <b>Nombre de compétitions restantes :</b> { props.subscription.competitionsLeft }<br />
                    <b>Licence renouvelée le :</b> { subscriptionModelConverter.getFormattedDate(props.subscription.endDate) }<br /><br />
                    <StyledLink href="https://billing.stripe.com/p/login/3cscMYaQY1T5g9ieUU" target="_blank">Gérer mon abonnement</StyledLink>
                </Counters>
            }

        </StyledDiv>

    )

}

export default LicenseInfo;