import styled from "styled-components";
import TextInput from "../../Inputs/TextInput";
import { useEffect, useState } from "react";
import CheckboxInput from "../../Inputs/CheckboxInput";
import fetchAPI from "../../../../utils/fetch";
import { useNavigate } from "react-router-dom";
import playerConverter from "../../../../utils/modelConverters/player";
import API_HOST_URL from "../../../../utils/apiHost";

/**
 * Styles
 */

const StyledDiv = styled.div `

    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background:rgba(0,0,0,0.5);
    z-index:999;

    & > div > div.close {
        position:absolute;
        top:0;
        right:0;
        padding:8px;
        font-size:28px;
        color:#bdc3c7;
        cursor:pointer;
        transition:all .3s ease;
    }

    & > div > div.close:hover {
        color:#e74c3c;
        transition:all .3s ease;
    }

    & > div {
        position:fixed;
        top:10%;
        left:calc(50% - 400px);
        width:800px;
        background:white;
        border-radius:8px;
        display:flex;
        flex-direction:column;
    }

    & > div > h3 {
        margin:30px;
        font-size:24px;
    }

    & > div > h3 > span.neoscore {
        color:#f1c40f;
    }

    & > div > h3 > span.minalert {
        color:#e74c3c;
        font-size:10px;
    }

    & > div > div.email {
        margin:0 30px;
        display:flex;
        flex-direction:row;
        align-items:center;
        justify-content:space-between;
        padding: 0 0 20px 0;
    }

    & > div > div.profile {
        margin:0 30px;
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:space-between;
        border-bottom:1px solid #bdc3c7;
        padding: 0 0 20px 0;
    }

    & > div > div.payment {
        margin:0 30px;
        display:flex;
        flex-direction:row;
        align-items:center;
        justify-content:space-between;
        padding: 20px 0;
        border-bottom:1px solid #bdc3c7;
    }

    & > div > div.payment > div:first-child {
        display:flex;
        flex-direction:row;
        align-items:center;
        justify-content:space-between;
    }

    & > div > div.payment > div:first-child > div:first-child {
        width:40px;
        height:40px;
        margin:0 10px 0 0;
    }

    & > div > div.payment > div:first-child > div:nth-child(2) {
        font-size:14px;
        font-weight:500;
    }

    & > div > div.payment > div:nth-child(2) {
        display:flex;
        align-items:center;
        justify-content:start;
        background:#2ecc71;
        color:white;
        border-radius:8px;
        transition:all .3s ease;
    }

    & > div > div.payment > div:nth-child(2) > ion-icon {
        margin:0 10px 0 6px;
        font-size:28px;
    }

    & > div > div.payment > div:nth-child(2) > p {
        margin:10px 10px 10px 0;
        font-weight:600;
    }

    & > div > div.payment > div:nth-child(2):hover {
        background:#27ae60;
        cursor:pointer;
        transition:all .3s ease;
    }

    & > div > div.profile > div {
        height:84px;
        width:100%;
        border-radius:8px;
        margin:6px 0;
        -webkit-box-shadow: 0px 0px 13px -5px rgba(0,0,0,0.36); 
        box-shadow: 0px 0px 13px -5px rgba(0,0,0,0.36);
        display:flex;
        justify-content:start;
        align-items:center;
        line-height:54px;
        font-size:14px;
        font-weight:500;
        cursor:pointer;
        transition:all .3s ease;
    }

    & > div > div.profile > p.noresult {
        color:red;
        font-weight:500;
        text-align:center;
        font-size:14px;
    }

    & > div > div.profile > div.selected {
        background:#34495e;
        color:white;
    }

    & > div > div.profile > div > div:nth-child(2) {
        margin:0 0 0 20px;
        font-size:16px;
    }

    & > div > div.email > label {
        font-size:18px;
        margin:0 20px 0 0;
    }

    & > div > div.email > div.input {
        width:340px;
        height:38px;
    }

    & > div > div.email > div.btn {
        background:#2ecc71;
        color:white;
        height:40px;
        display:flex;
        flex-direction:row;
        align-items:center;
        font-size:14px;
        border-radius:8px;
        padding:3px 8px;
        transition:all .3s ease;
    }

    & > div > div.email > label {
        font-size:16px;
        font-weight:500;
    }

    & > div > div.email > div.btn:hover {
        background:#27ae60;
        transition:all .3s ease;
        cursor:pointer;
    }

    & > div > div.email > div.btn > ion-icon {
        font-size:20px;
    }

    & > div > div.form {
        margin:0 30px;
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:space-between;
        border-bottom:1px solid #bdc3c7;
        padding: 10px 0 10px 0;
    }

    & > div > div.form > div {
        display:flex;
        flex-direction:row;
        justify-content:space-between;
        width:100%;
    }

    & > div > div.form > div > div {
        display:flex;
        flex-direction:row;
        margin:5px 0;
        align-items:center;
    }

    & > div > div.form > div > div > label {
        font-weight:500;
        margin: 0 10px 0 0;
    }

    & > div > div.form > div > div > div {
        width:260px;
        height:38px;
    }

    .del {
        display:none!important;
    }

`

/**
 * Component
 * @param {*} props 
 * @returns 
 */
const EditIllustrativePlayerForm = (props) => {

    // New player to add
    const [name, setName] = useState("");
    const [firstname, setFirstname] = useState("");
    const [payed, setPayed] = useState(false);

    // If player is selected then fill
    useEffect(() => {

        if(props.illustrativePlayer) {
            setFirstname(props.illustrativePlayer.firstname);
            setName(props.illustrativePlayer.name);
            setPayed(props.illustrativePlayer.payed);
        }

    }, [props.illustrativePlayer])

    // Handlers

    const handleChangeFirstname = (e) => {setFirstname(e.target.value);};
    const handleChangeName = (e) => {setName(e.target.value); };
    const handleChangePayed = (b) => {setPayed(b);};

    const handleSubmitPlayer = () => {

        props.onAddIllustrativePlayer({
            firstname: firstname,
            name: name,
            payed: payed,
            updateIndex: props.illustrativePlayer ? props.illustrativePlayer.index : -1
        });

    }

    return (
        <StyledDiv>

            <div>

                <div className="close" onClick={props.hideForm}><ion-icon name="close"></ion-icon></div>

                <h3>{ props.illustrativePlayer ? "Modifier le joueur de l'équipe" : "Ajouter un joueur à l'équipe"}</h3>
                
                <div className="form">
                    <div>
                        <div>
                            <label>Prénom</label>
                            <div><TextInput placeholder="Prénom du joueur" value={firstname} onChange={handleChangeFirstname} /></div>
                        </div>
                        <div>
                            <label>Nom</label>
                            <div><TextInput placeholder="Nom du joueur" value={name} onChange={handleChangeName} /></div>
                        </div>
                    </div>
                    
                </div>
               
                <div className="payment">
                    <div>
                        <div><CheckboxInput value={payed} onChange={handleChangePayed} /></div>
                        <div>Inscription réglée</div>
                    </div>
                    <div onClick={ handleSubmitPlayer }>
                        <ion-icon name="add-circle"></ion-icon>
                        <p>{props.illustrativePlayer ? "Enregistrer les modifications" : "Ajouter le joueur à l'équipe" }</p>
                    </div>
                </div>

            </div>

        </StyledDiv>
    );

}

export default EditIllustrativePlayerForm;