import styled from "styled-components";
import API_HOST_URL from "../../../utils/apiHost";

const StyledDiv = styled.div `

    position:relative;
    top:0;
    left:0;
    width:20%;


`

const ImageClient = styled.img `

    width:200px;

`

const ImageAccount = (props) => {

    return (

        <StyledDiv className={ props.isLoading ? "loading_content" : "loaded_content" }>
            { props.pro && <ImageClient src={ API_HOST_URL + "/pro/getlogo?proId=" + props.pro.proId} alt="Logo JSA" /> }
        </StyledDiv>

    )

}

export default ImageAccount;