import styled from "styled-components";
import ValidationSection from "../../components/Software/ValidationSection/ValidationSection";
import TextInput from "../../components/Software/Inputs/TextInput";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import levelValidator from "../../utils/validators/level";
import Wrapper from "../../components/Software/Wrapper/Wrapper";
import Section from "../../components/Software/Section/Section";
import FormBlock from "../../components/Software/FormBlock/FormBlock";
import fetchAPI from "../../utils/fetch";
import { useCookies } from "react-cookie";
import NumberInput from "../../components/Software/Inputs/NumberInput";
import HeaderWrapper from "../../components/Software/Wrapper/HeaderWrapper/HeaderWrapper";

/**
 * Styles
 */

const LevelNameBlock = styled.div `
    width:300px;
    height:32px;
`

const LevelCoefficientBlock = styled.div `
    width:82px;
    height:32px;
`

/**
 * Component
 * @param {*} param0 
 * @returns 
 */
const Level = ({ match }) => {

    const { id } = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [renderButton, setRenderButton] = useState(0);
    const [error, setError] = useState(null);
    const [levelName, setLevelName] = useState("");
    const [levelCoeff, setLevelCoeff] = useState(0);
    const [cookies] = useCookies(['competition']); 

    /**
     * Load level from database
     */
    useEffect(() => {

        if(!id) return;

        const fetchLevel = async () => {

            try {

                setIsLoading(true);

                const {data, error} = await fetchAPI("GET", "/level/get/pro", {
                    levelId: parseInt(id)
                });

                if(error) throw new Error();

                setLevelName(data && data.level ? data.level.name : "");
                setLevelCoeff(data && data.level ? data.level.coeff : 1);
                setIsLoading(false);

            }
            catch(e) {
                
                navigate('/e');
                return;

            }

        }

        fetchLevel();

        return () => {};

    }, [id]);

    // Handlers

    const handleChangeLevelName = (e) => {setLevelName(e.target.value); setError(null); };
    const handleChangeLevelCoeff = (e) => {setLevelCoeff(e.target.value); setError(null); };

    /**
     * Submit form handler
     */
    const handleSubmitForm = async () => {

        try {

            levelValidator(levelName, parseInt(levelCoeff));

            let method = 'POST';
            let endpoint = 'create';

            let level = {
                name: levelName,
                coeff: parseInt(levelCoeff),
                competitionId: cookies.competition
            }

            if(id) {
                method = "PUT";
                endpoint = 'update';
                level = {
                    ...level,
                    levelId: id
                }
            }

            try {

                const {error} = await fetchAPI(method, '/level/' + endpoint + '/pro', {
                    level: level
                });

                if(error) throw new Error();

                navigate('/levels', {
                    state: {
                        success: "Le niveau a été créé/modifié."
                    }
                });

            }
            catch(e) {
                throw new Error();
            }

        }
        catch(e) {
            
            navigate('/e');
            return;

        }

    }

    return (

        <Wrapper isLoading={isLoading} popup={[error]}>

        <HeaderWrapper title={id ? "Modifier le niveau" : "Ajouter un niveau"} showSearchEngine={false} />

            <Section align="start">

                <FormBlock>
                    <label>Nom</label>
                    <LevelNameBlock>
                        <TextInput placeholder="Nom du niveau" value={levelName} onChange={handleChangeLevelName} />
                    </LevelNameBlock>
                </FormBlock>

                <FormBlock>
                    <label>Coefficient</label>
                    <LevelCoefficientBlock>
                        <NumberInput placeholder="Coeff" value={levelCoeff} onChange={handleChangeLevelCoeff} />
                    </LevelCoefficientBlock>
                </FormBlock>

            </Section>

            <ValidationSection icon="save" message1="Enregistrer" message2="le niveau" callback={handleSubmitForm} renderButton={renderButton} />

        </Wrapper>
        
    );

}

export default Level;