import styled from "styled-components"

/**
 * Styles
 */

const TitleWrapperArea = styled.div `

    position:relative;
    top:0;
    left:0;
    width:100%;

`

const TitleWrapperText = styled.h1 `

    margin:20px 0 30px 0;
    font-size:56px;

`

/**
 * Component
 * @param {*} props 
 * @returns 
 */
const TitleWrapper = (props) => {

    return (
        <TitleWrapperArea>
            <TitleWrapperText>{props.title}</TitleWrapperText>
        </TitleWrapperArea>
    );
    
}

export default TitleWrapper;