import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

const ErrorPage = styled.div `

    position:relative;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background:#e74c3c;
    display:flex;
    align-items:center;
    justify-content:center;

`

const NeoscoreLogo = styled.img `

    width:70px;

`;

const ErrorBox = styled.div `

    display:flex;
    flex-direction:column;
    color:white;
    width:800px;

`;

const TitleError = styled.h2 `

    font-size:34px;

`;

const TextError = styled.p `

    font-size:18px;

`;

const ButtonReturn = styled.div `

    background:white;
    border-radius:8px;
    width:240px;
    color:black;
    padding:10px 14px;
    font-size:14px;
    margin:20px 0;

`;

const LinkDef = styled.a `

    text-decoration:none;

`

/**
 * Component
 * @returns 
 */
const Error = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const [errorTitle, setErrorTitle] = useState(null);
    const [errorText, setErrorText] = useState(null);

    useEffect(() => {
        if(location.state?.error) {
            setErrorTitle(location.state.error.title);
            setErrorText(location.state.error.text);
        }
    }, [location.state?.error, navigate]);

    return (

        <ErrorPage>

            <ErrorBox>
                <NeoscoreLogo src="/logo_white.png" />
                <TitleError>{ errorTitle ?? "Neoscore a rencontré une erreur innatendue" }</TitleError>
                <TextError>{ errorText ?? "Le logiciel a rencontré une erreur innatendue qui l'a empêché de fonctionner normalement. Si le problème persiste, n'hésitez pas à signaler cette erreur à notre équipe technique." }</TextError>
                <LinkDef href="/">
                    <ButtonReturn>Retourner sur votre espace</ButtonReturn>
                </LinkDef>
            </ErrorBox>

        </ErrorPage>
    );
}

export default Error;