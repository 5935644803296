import styled from "styled-components";
import proModelConverter from "../../../../utils/modelConverters/pro";

const StyledDiv = styled.div `

    position:relative;
    top:0;
    left:0;
    width:100%;
    background:rgba(236, 240, 241,0.5);
    padding:10px 20px 20px 20px;
    margin:20px 0;

`

const Title = styled.h2 `

    font-size:26px;
    font-weight:bold;
    font-size:20px;
    color:black;
    font-size:16px;
    margin:10px 0;

`

const Counters = styled.div `

    position:relative;
    top:0;
    left:0;
    width:100%;

`

const PersonalInfo = (props) => {

    return (

        <StyledDiv className={ props.isLoading ? "loading_content" : "loaded_content" }>

            <Title>Informations personnelles</Title>

            {
                props.pro &&
                <Counters>
                    <b>Nom de l'organisation :</b> { props.pro.name }<br />
                    <b>Email rattaché :</b> { props.pro.email }<br />
                    <b>Mot de passe :</b> ************<br />
                    <b>Membre depuis :</b> { proModelConverter.getFormattedDate(props.pro.createdAt) }<br />
                </Counters>
            }

        </StyledDiv>

    )

}

export default PersonalInfo;