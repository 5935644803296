import { useNavigate } from "react-router-dom";
import API_HOST_URL from "./apiHost";

const getCookieValue = (name) => {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

const fetchAPI = async (method, url, payload) => {

    // Get authorization token
    const token = getCookieValue("proAuthorization") ? getCookieValue("proAuthorization") : "none"

    try {

        // Get API HOST URL
        const API_HOST = API_HOST_URL;

        // Create an array for options
        let options = {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        };

        // GET manager
        if (method === "GET" && payload) {
            const params = new URLSearchParams();
            Object.entries(payload).forEach(([key, value]) => {
                params.append(key, value);
            });
            url += `?${params.toString()}`;

        // POST, PUT and DELETE manager
        } else if (payload) {
            options.body = JSON.stringify(payload);
        }

        // Get response as json
        const response = await fetch(`${API_HOST}${url}`, options);
        const jsondata = await response.json();
    
        if(response.status !== 200 && jsondata.message === "ERR_PLEASE_RECONNECT") {
            window.location.replace('http://' + window.location.host + '/login');
        }

        // Return data
        return {
            data: jsondata.data,
            error: response.status !== 200 ? jsondata.message : null
        };

    } catch (e) {
        throw e;
    }

};

export default fetchAPI;