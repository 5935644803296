import styled from "styled-components";
import CheckboxInput from "./../../Inputs/CheckboxInput";

const AreaPicker = styled.div `

    width:calc(100% - 60px);
    margin:30px;
    height:25%;
    overflow:auto;

    &::-webkit-scrollbar {
        width: 6px;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 10px;
    }
    
    &::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0.05);
        border-radius: 10px;
    }

`

const StyledTable = styled.table `

    margin:0;
    width:100%;
    border-collapse:collapse;

    & > thead > tr > td {
        font-size:13px;
        font-weight:900;
        font-style:italic;
        text-transform:uppercase;
        padding:0 0 8px 0;
    }

    & > tbody > tr:nth-child(odd) {
        background:#ecf0f1;
    }

    & > tbody > tr {
        height:36px;
    }

    & > tbody > tr > td {
        font-size:14px;
        font-weight:600;
        transition:all .3s ease;
    }

    & > tbody > tr > td:first-child {
        padding:0 0 0 5px;
    }

    & > tbody > tr > td.gold {
        color:#f1c40f;
    }

    & > tbody > tr > td.silver {
        color:#bdc3c7;
    }

    & > tbody > tr > td.bronze {
        color:#CD7F32;
    }

    & > tbody > tr:hover {
        background:#bdc3c7;
        transition:all .3s ease;
        cursor:pointer;
    }

`

const PickerDiv = styled.div `

    width:20px;
    height:20px;

`

const TourPicker = (props) => {

    const isSelected = (id) => {
        const filteredClassement = props.classement.filter((c) => (c.teamId === id));
        return filteredClassement[0] && filteredClassement[0].selectedForAfter;
    }

    return (

        props.classement && props.onChange &&

        (

        !props.isFirstTour ?

        <>

            <AreaPicker>
                <StyledTable>

                    <thead>
                        <tr>
                            <td></td>
                            <td>Equipe</td>
                            <td>Position</td>
                            <td>Pos. poule</td>
                            <td>Matchs gagnés</td>
                            <td>Ratios</td>
                            <td>Status</td>
                        </tr>
                    </thead>

                    <tbody>

                        { props.classement.map((c) => (
                            <tr key={ c.teamId } >
                                <td>
                                    <PickerDiv>
                                        <CheckboxInput value={ isSelected(c.teamId) } onChange={() => props.onChange(c.teamId)} />
                                    </PickerDiv>
                                </td>
                                <td>{ c.name }</td>
                                <td className={ c.position === 1 ? "gold" : (c.position === 2 ? "silver" : (c.position === 3 ? "bronze" : "")) }>#{ c.position }</td>
                                <td className={ c.positionInPoule === 1 ? "gold" : (c.positionInPoule === 2 ? "silver" : (c.positionInPoule === 3 ? "bronze" : "")) }>#{ c.positionInPoule }</td>
                                <td>{ c.wonGames }</td>
                                <td>{ c.ratios.map((r) => r) }</td>
                                <td className={c.available === false ? "success" : "error"}>{ c.available === false ? "Engagée": "Stoppée"}</td>
                            </tr>
                        )) }

                    </tbody>

                </StyledTable>

            </AreaPicker>

        </>

        :

        <>

            <AreaPicker>
                <StyledTable>

                    <thead>
                        <tr>
                            <td></td>
                            <td>Equipe</td>
                            <td>Niveau</td>
                        </tr>
                    </thead>

                    <tbody>

                        { props.classement.map((c) => (
                            <tr key={ c.teamId } >
                                <td>
                                    <PickerDiv>
                                        <CheckboxInput value={ isSelected(c.teamId) } onChange={() => props.onChange(c.teamId)} />
                                    </PickerDiv>
                                </td>
                                <td>{ c.name }</td>
                                <td>{ c.level }</td>
                            </tr>
                        )) }

                    </tbody>

                </StyledTable>
            </AreaPicker>

        </>

        )
        
    )

}

export default TourPicker;