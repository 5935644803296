import { Link } from "react-router-dom";
import styled from "styled-components";
import moment from "moment"
import API_HOST_URL from "../../../../../../../utils/apiHost";

const ProfileLinksArea = styled.div`

    z-index:9999;
    position:absolute;
    top:100px;
    right:50px;
    background:white;
    border-radius:12px;
    -webkit-box-shadow: 0px 0px 13px -5px rgba(0,0,0,0.56); 
    box-shadow: 0px 0px 13px -5px rgba(0,0,0,0.56);
    display:flex;
    flex-direction:column;
    align-items:center;

    & > div.pic {
        display:flex;
        align-items:center;
        justify-content:start;
        width:100%;
        margin:16px 0 0 0;
        padding: 0 0 16px 0;
        border-bottom:1px solid #ecf0f1;
    }

    & > div.pic > div:first-child {
        padding:0 0 0 20px;
    }

    & > div.pic > div:nth-child(2) {
        padding:0 25px 0 20px;
    }

    & > div.pic > div > img {
        width:50px;
        border-radius:50%;
    }

    & > div.pic > div > h4 {
        margin:0 0 4px 0;
        font-size:16px;
    }

    & > div.pic > div > h5 {
        margin:0;
        font-size:13px;
        color:grey;
        font-weight:normal;
    }

    & > div.license {
        display:flex;
        align-items:center;
        justify-content:space-between;
        width:100%;
        margin:10px 0 0 0;
        padding: 0 26px 10px 20px;
    }

    & > div.license > div:first-child {
        font-size:14px;
        color:grey;
        font-weight:500;
    }

    & > div.license > div:nth-child(2) {
        font-size:13px;
        background:rgba(241, 196, 15,0.5);
        padding:4px 8px;
        border-radius:8px;
        color:#f39c12;
        font-weight:bold;
        text-transform:uppercase;
    }

    & > div.dte {
        display:flex;
        align-items:center;
        justify-content:space-between;
        width:100%;
        margin:0;
        padding: 0 20px 0 20px;
    }

    & > div.dte.bordered {
        border-bottom:1px solid #ecf0f1;
        padding: 0 20px 10px 20px;
    }

    & > div.dte > div:first-child {
        font-size:14px;
        color:grey;
        font-weight:500;
    }

    & > div.dte > div:nth-child(2) {
        font-size:13px;
        padding:4px 8px;
        border-radius:8px;
        color:grey;
        font-weight:normal;
    }

    & > div.links {
        display:flex;
        flex-direction:column;
        align-items:start;
        justify-content:space-between;
        width:100%;
        margin:10px 0 0 0;
        padding: 0 20px 10px 20px;
    }

    & > div.links .lk {
        color:black;
        text-decoration:none;
        display:flex;
        align-items:center;
        padding:8px 5px;
        margin:3px 0;
        border-radius:8px;
        width:100%;
        font-size:16px;
        transition:all .3s ease;
        font-weight:500;
    }

    & > div.links .lk:hover {
        background:#ecf0f1;
        transition:all .3s ease;
    }

    & > div.links .lk ion-icon {
        font-size:20px;
        padding:0 5px 0 0;
    }

`

const ProfileLinks = (props) => {

    return (

        props.pro && props.subscription &&

        <ProfileLinksArea>

            <div className="pic">
                <div>
                    <img src={ API_HOST_URL + "/pro/getlogo?proId=" + props.pro.proId} alt="Pro Profile" />
                </div>
                <div>
                    <h4>{props.pro.name}</h4>
                    <h5>{props.pro.email}</h5>
                </div>
            </div>
            <div className="license">
                <div>Licence</div>
                <div>{props.subscription.name}</div>
            </div>
            <div className="dte">
                <div>Periode</div>
                <div>{moment(new Date(props.subscription.startDate)).format("DD/MM/YYYY")}</div>
            </div>
            <div className="dte bordered">
                <div>Fin de validité</div>
                <div>{moment(new Date(props.subscription.endDate)).format("DD/MM/YYYY")}</div>
            </div>
            <div className="links">
                <a href="/account" className="lk"><ion-icon name="person-outline"></ion-icon>Mon compte</a>
                <Link to="/logout" className="lk"><ion-icon name="log-out-outline"></ion-icon>Déconnexion</Link>
            </div>
        </ProfileLinksArea>

    )
}

export default ProfileLinks;