import styled from "styled-components";
import Row from "../../Table/Row/Row";
import { useNavigate } from "react-router-dom";
import ButtonsArea from "../../Table/Row/ButtonsArea/ButtonsArea";
import Button from "../../Table/Row/ButtonsArea/Button/Button";
import { CSSTransition } from "react-transition-group";
import { useEffect, useState } from "react";

const LevelNameBox = styled.div `

    position:absolute;
    left:15px;

`

const LevelCoeffBox = styled.div `

    position:absolute;
    left:30%;

`

/**
 * Component
 * @param {*} props 
 * @returns 
 */
const LevelBlock = (props) => {

    // Get navigation
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);

    /**
     * Loading animation
     */
    useEffect(() => {
        setLoaded(true);
        return () => setLoaded(false);
    }, [props.level]);

    /**
     * Open handler
     * @param {*} id 
     */
    const openLevel = (id) => {
        navigate('/level/' + id);
    }

    return (

        props.level && props.deleteLevel &&

        <CSSTransition in={loaded} timeout={300} classNames="fade" unmountOnExit>
            <Row onMouseOver={props.onHover} onClick={() => openLevel(props.level.levelId)}>
                <LevelNameBox>{props.level.name}</LevelNameBox>
                <LevelCoeffBox>{props.level.coeff}</LevelCoeffBox>
                <ButtonsArea>
                    <Button type="error" onClick={(event) => props.deleteLevel(event, props.level.levelId)} icon="trash" />
                </ButtonsArea>
            </Row>
        </CSSTransition>

    );
    
}

export default LevelBlock;