import styled from "styled-components";
import Popup from "../Popup/Popup";
import Loader from "../../Loader/Loader";
import { useEffect } from "react";

/**
 * Styles
 */

const WrapperArea = styled.div `

    display:flex;
    flex-direction:column;
    justify-content:start;
    width:100%;
    height:100%;
    position:relative;

`

/**
 * Component
 * @param {*} props 
 * @returns 
 */
const Wrapper = (props) => {

    return (
        <WrapperArea>
            <Popup content={props.popup ?? []} />
            <Loader isLoading={props.isLoading ?? false} />
            {props.children}
        </WrapperArea>
    );

}

export default Wrapper;