import styled from "styled-components"
import Indicator from "./Indicator/Indicator";
import competitionModelConverter from "../../../utils/modelConverters/competition";
import { useEffect, useState } from "react";
import fetchAPI from "../../../utils/fetch";
import { useNavigate } from "react-router-dom";

/**
 * Styles
 */

const IndicatorsWrapper = styled.div `

    position:relative;
    top:0;
    left:0;
    width:100%;
    justify-content:space-between;
    flex-direction:row;
    display:flex;

`

/**
 * Components
 * @returns 
 */
const Indicators = (props) => {

    
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(props.isLoading);
    }, [props.isLoading]);

    return (

        <IndicatorsWrapper>
            <Indicator main={ props.nbCurrentGames } desc="matchs en cours" isLoading={isLoading} />
            <Indicator main={ props.nbTeams } desc="équipes engagées" isLoading={isLoading} />
            <Indicator main={ props.nbAvailableFields } desc="terrains disponibles" isLoading={isLoading} />
            <Indicator main={ props.nbTotalFields } desc="terrains au total" isLoading={isLoading} />
        </IndicatorsWrapper>

    );

}

export default Indicators;