import styled from "styled-components";
import FieldBlock from "./FieldBlock/FieldBlock";
import EmptyList from "../EmptyList/EmptyList";
import { useEffect, useState } from "react";

/**
 * Styles
 */

const FieldListWrapper = styled.div `

    position:relative;
    display:flex;
    flex-direction:column;
    top:0;
    left:0;
    width:100%;
    border-collapse: collapse;
    overflow-y:auto;
    height:100%;
    border-spacing:0 10px;

    & > div {
        opacity: ${props => (props.isLoading ? 0 : 1)};
        transition: opacity 0.25s ease-in-out;
    }

`

/**
 * Component
 * @param {*} props 
 * @returns 
 */
const FieldList = (props) => {

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(props.isLoading);
    }, [props.isLoading])

    return (

        props.fields && props.deleteField &&

        <>

            {props.fields.length === 0 ? <EmptyList link={{path: '/add-field', text: "Ajoutez votre premier terrain"}} /> : null}
            
            <FieldListWrapper isLoading={isLoading}>
                { props.fields.map((field) => (
                    <FieldBlock key={field.fieldId} field={field} deleteField={props.deleteField} />
                )) }
            </FieldListWrapper>

        </>

    );
    
}

export default FieldList;