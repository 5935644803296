import styled, { keyframes } from "styled-components";
import { useEffect, useState } from "react";

/**
 * Styles
 */

const fadeIn = keyframes`
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
`;

const fadeOut = keyframes`
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
`;

const PopupWrapper = styled.div `

    position: fixed;
    top: 0;
    right: 0;
    color: white;
    font-weight: bold;
    height: 60px;
    z-index:99999;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    border-radius: 8px;
    animation: ${fadeIn} 0.5s forwards;

    &.error {
        background: rgba(231, 76, 60, 0.96);
    }

    &.success {
        background: rgba(46, 204, 113, 1.0);
    }

    &.fade-out {
        animation: ${fadeOut} 0.5s forwards;
    }

    & > ion-icon {
        font-size: 30px;
        padding: 10px 10px 10px 0;
    }
`;

/**
 * Components
 * @param {*} props 
 * @returns 
 */
const Popup = (props) => {

    const [visible, setVisible] = useState(true);

    let type = null;
    let message = null;

    if (props.content && props.content[0] && props.content[0] !== null) {
        type = "error";
        message = props.content[0];
    }
    if (props.content && props.content[1] && props.content[1] !== null) {
        type = "success";
        message = props.content[1];
    }

    useEffect(() => {
        if (type) {
            setVisible(true);
            const timer = setTimeout(() => {
                setVisible(false);
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [props.content, type]);

    if (!visible) return null;

    return (

        type &&

        <PopupWrapper className={`${type} ${!visible ? 'fade-out' : ''}`}>
            {type === "error" ? <ion-icon name="alert-circle"></ion-icon> : <ion-icon name="checkmark-circle"></ion-icon>}
            <p>{message}</p>
        </PopupWrapper>
    );
};

export default Popup;
