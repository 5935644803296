import styled from "styled-components";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { useCookies } from "react-cookie";
import fetchAPI from "../../utils/fetch";
import FieldList from "../../components/Software/FieldList/FieldList";
import Wrapper from "../../components/Software/Wrapper/Wrapper";
import HeaderWrapper from "../../components/Software/Wrapper/HeaderWrapper/HeaderWrapper";
import LargeButton from "../../components/Software/LargeButton/LargeButton";

/**
 * Styles
 */

const Cols = styled.div `

    position:relative;
    top:0;
    left:0;
    width:100%;
    height:85%;
    display:flex;
    flex-direction:row;
    justify-content:space-between;

`

const Col1 = styled.div `

    position:relative;
    top:0;
    left:0;
    width:80%;

`

const Col2 = styled.div `

    position:relative;
    top:0;
    left:0;
    width:17%;
    display:flex;
    justify-content:start;
    align-items:start;
    flex-direction:column;
    overflow-y:auto;

`

/**
 * Component
 * @returns 
 */
const Fields = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const [cookies] = useCookies(['competition']);
    const [fields, setFields] = useState([]);
    const [fieldsRestriction, setFieldsRestriction] = useState(null);
    const [fetchTrigger, setFetchTriger] = useState(0);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if(location.state?.success) setSuccess(location.state.success);
    }, [location.state?.success])

    /**
     * Load fields from database
     */
    useEffect(() => {

        const fetchFields = async () => {

            try {

                setIsLoading(true);

                const {data, error} = await fetchAPI("GET", "/field/getallofcompetition/pro", {
                    competitionId: parseInt(cookies.competition)
                });

                if(error) throw new Error();

                setFields(data && data.fields ? data.fields : []);
                setIsLoading(false);

            }
            catch(e) {
                navigate('/e');
                return;
            }

        }

        fetchFields();

        return () => {};

    }, [cookies.competition, fetchTrigger, navigate]);

    /**
     * Add btn handler
     * @param {*} e 
     */
    const handleRedirectToAddPage = (e) => {
        e.preventDefault();
        navigate("/add-field");
    }

    /**
     * Search engine handler
     * @param {*} e 
     */
    const handleSearch = (e) => {

        const search = e.target.value;

        if(search !== null && search.length !== 0) {

            setFieldsRestriction(fields.filter((f) => {
                return f.name.toLowerCase().includes(search.toLowerCase());
            }));

        } else {
            setFieldsRestriction(null);
        }

    }

    /**
     * Delete handler
     * @param {*} event 
     * @param {*} id 
     * @returns 
     */
    const handleDelete = async (event, id) => {

        event.stopPropagation();

        const cf = window.confirm("Souhaitez-vous vraiment supprimer le terrain ?");

        if(cf) {

            try {

                setIsLoading(true);

                const {error} = await fetchAPI('DELETE', '/field/delete/pro', {
                    fieldId: id
                });

                if(error) if(error === "ERR_LINKED_GAMES") throw new Error("Des matchs sont liés à ce terrain.");

                setFetchTriger(fetchTrigger + 1);

            }
            catch(e) {

                setIsLoading(false);

                if(e.message.includes('matchs')) {
                    setError(e.message);
                    return;
                }

                navigate('/e');

            }

        }

    }

    return (

        fields &&

        <Wrapper isLoading={isLoading} popup={[error, success]}>

            <HeaderWrapper title="Gestion des terrains" showSearchEngine={true} searchEnginePlaceholder="Rechercher des terrains ..." placeholderOnChange={handleSearch} />

            <Cols>

                <Col1>
                    <FieldList fields={fieldsRestriction === null ? fields : fieldsRestriction} deleteField={handleDelete} isLoading={isLoading} />
                </Col1>

                <Col2 className={isLoading ? "loading_content" : "loaded_content"}>
                    <LargeButton message1="Ajouter" message2="un terrain" callback={handleRedirectToAddPage} />
                </Col2>

            </Cols>

        </Wrapper>

    );

}

export default Fields;