import styled from "styled-components"
import TourCategory from "./TourCategory/TourCategory"
import { useEffect, useState } from "react";

/**
 * Styles
 */

const TourStateWrapper = styled.div `

    position:relative;
    top:0;
    left:0;
    width:calc(100% - 50px);
    display:flex;
    flex-direction:column;
    margin:0 25px 0 25px;

`

/**
 * Component
 * @returns 
 */
const TourState = (props) => {

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(props.isLoading);
    }, [props.isLoading]);

    return (

        props.tours &&
        
        <TourStateWrapper className={isLoading ? "loading_content" : "loaded_content"}>
            <TourCategory title="Terminés" type="pending" tours={props.tours.filter((t) => t.completion === 1)} />
            <TourCategory title="En cours" type="done" tours={props.tours.filter((t) => t.completion !== 1)} />
        </TourStateWrapper>

    );
    
}

export default TourState;