import styled, { keyframes } from "styled-components"

/**
 * Styles
 */

const slide = keyframes`

  0% {
    transform: translateX(-100%);
    opacity: 0.3;
  }
  50% {
    transform: translateX(100%);
    opacity: 1;
  }
  100% {
    transform: translateX(300%);
    opacity: 0.3;
  }

`;

const LoaderArea = styled.div `

    position:fixed;
    top:0;
    left:120px;
    width:calc(100% - 120px);
    height:5px;
    background:rgba(241, 196, 15, 0.3);
    z-index:9999;
    display:flex;
    justify-content:center;
    align-items:center;

`

const ProgressBar = styled.div `

  width: 30%; /* Largeur de l'élément "coulissant" */
  height: 100%;
  background:rgba(241, 196, 15, 1);
  opacity: 0.6; /* Opacité initiale */
  animation: ${slide} 1.5s ease-in-out infinite;

`



/**
 * Component
 * @param {*} props 
 * @returns 
 */
const Loader = (props) => {

    return (

        props.isLoading &&

        <LoaderArea>
            <ProgressBar />
        </LoaderArea>

    );

}

export default Loader;