import styled from "styled-components";
import Indicators from "../../components/Software/Indicators/Indicators";
import Emmet from "../../components/Software/Emmet/Emmet";
import Logs from "../../components/Software/Logs/Logs";
import TourState from "../../components/Software/TourState/TourState";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import fetchAPI from "../../utils/fetch";
import { useNavigate } from "react-router-dom";
import Popup from "../../components/Software/Popup/Popup";
import Loader from "../../components/Loader/Loader";

/**
 * Styles
 */

const DashboardWrapper = styled.div `

    display:flex;
    flex-direction:column;
    justify-content:space-between;
    width:100%;
    height:100%;
    position:relative;

`

const FlexDashboardContainer = styled.div `

    position:relative;
    display:flex;
    top:0;
    left:0;
    width:100%;
    justify-content:space-between;
    margin:30px 0 4px 0;
    height:100%;
    overflow:hidden;

    & > div:first-child {
        width:30%;
    }

    & > div:nth-child(2) {
        width:66%;
    }

`

/**
 * Component
 * @returns 
 */
const Dashboard = () => {

    const navigate = useNavigate();
    const [cookies] = useCookies(['proAuthorization', 'competition']);
    const [competition, setCompetition] = useState(null);
    const [tours, setTours] = useState([]);
    const [logs, setLogs] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const [nbCurrentGames, setNbCurrentGames] = useState(0);
    const [nbTeams, setNbTeams] = useState(0);
    const [nbAvailableFields, setNbAvailableFields] = useState(0);
    const [nbTotalFields, setNbTotalFields] = useState(0);
    /**
     * Fetch dashboard
     */
    useEffect(() => {

        const fetchDashboard = async () => {

            if(!cookies.competition) return;

            setIsLoading(true);

            try {

                const {data, error} = await fetchAPI('GET', '/general/getdashboard/pro', {
                    competitionId: parseInt(cookies.competition)
                });

                if(error) throw new Error();
                
                setTours(data.tours);
                setNbCurrentGames(data.currentGames);
                setNbTeams(data.teams);
                setNbTotalFields(data.totalFields);
                setNbAvailableFields(data.availableFields);
                setLogs(data.logs);
                setIsLoading(false);
    
            }
            catch(e) {

                navigate('/e');
                return;

            }

        }

        fetchDashboard();

    }, [cookies.competition, navigate]);

    return (

        <>

            <Loader isLoading={isLoading} />

            <DashboardWrapper>

                    <Popup content={[error]} />

                    <Indicators nbCurrentGames={nbCurrentGames} nbTeams={nbTeams} nbTotalFields={nbTotalFields} nbAvailableFields={nbAvailableFields} isLoading={isLoading} />

                    <FlexDashboardContainer>

                        <Emmet title="Journaux" isLoading={isLoading}>
                            <Logs logs={competition ? competition.logs : null} isLoading={isLoading} />
                        </Emmet>

                        <Emmet title="Etat des tours" isLoading={isLoading}>
                            <TourState tours={tours} isLoading={isLoading} />
                        </Emmet>

                    </FlexDashboardContainer>

                </DashboardWrapper>

        </>

    );
    
}

export default Dashboard;