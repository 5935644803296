import styled from "styled-components";
import PlayerBlock from "./PlayerBlock/PlayerBlock"
import { useState } from "react";
import EditPlayerForm from "./EditPlayerForm/EditPlayerForm";
import playerValidator from "../../../utils/validators/player";
import Popup from "../Popup/Popup";
import playerConverter from "../../../utils/modelConverters/player";
import illustrativePlayerValidator from "../../../utils/validators/illustrativePlayer";
import EditIllustrativePlayerForm from "./EditIllustrativePlayerForm/EditIllustrativePlayerForm";
import IllustrativePlayerBlock from "./IllustrativePlayerBlock/IllustrativePlayerBlock";

/**
 * Styles
 */

const StyledDiv = styled.div `

    position:relative;
    top:0;
    left:0;
    display:flex;
    width:100%;
    flex-direction:column!important;

`

const StyledBtn = styled.div `

    background:#2ecc71;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:start;
    width:200px;
    margin:10px 10px 10px 0;
    color:white;
    border-radius:8px;
    padding:6px 0;
    transition:all .3s ease;

    & > ion-icon {
        font-size:40px;
        margin:0 0 0 8px;
    }

    & > p {
        margin:0 0 0 8px;
        font-size:14px;
    }

    &:hover {
        background:#27ae60;
        transition:all .3s ease;
        cursor:pointer;
    }

`

const W = styled.div `
    display:flex;
    flex-direction:column;
    width:100%;
`

const ButtonsArea = styled.div `
    display:flex;
    flex-direction:row;
`

/**
 * Component
 * @param {*} props 
 * @returns 
 */
const PlayerList = (props) => {

    const [editPlayerForm, setEditPlayerForm] = useState(false);
    const [editIllustrativePlayerForm, setEditIllustrativePlayerForm] = useState(false);
    const [player, setPlayer] = useState(null);
    const [illustrativePlayer, setIllustrativePlayer] = useState(null);
    const [error, setError] = useState(null);

    /**
     * Handler for opening player form
     */
    const togglePlayerForm = () => {
        if(editPlayerForm) {
            setPlayer(null);
            setIllustrativePlayer(null);
        }
        setEditPlayerForm(editPlayerForm ? false : true);
    }

    /**
     * Handler for opening illustrative player form
     */
    const toggleIllustrativePlayerForm = () => {
        if(editIllustrativePlayerForm) {
            setPlayer(null);
            setIllustrativePlayer(null);
        }
        setEditIllustrativePlayerForm(editIllustrativePlayerForm ? false : true);
    }

    /**
     * Handler for selection of selected player
     * @param {*} player 
     */
    const updateSelectedPlayer = (player) => {
        setPlayer(player);
        togglePlayerForm();
    }
    /**
     * Handler for selection of selected illustrative player
     * @param {*} player 
     */
    const updateSelectedIllustrativePlayer = (player) => {
        setIllustrativePlayer(player);
        toggleIllustrativePlayerForm();
    }

    /**
     * Handler on add of illustrative player
     * @param {*} illustrativePlayer 
     */
    const onAddIllustrativePlayer = (illustrativePlayer) => {
        
        try {
            illustrativePlayerValidator(illustrativePlayer.firstname, illustrativePlayer.name);
            toggleIllustrativePlayerForm();
            props.onAddIllustrativePlayer(illustrativePlayer);
        }
        catch(e) {
            setError(e.message);
        }

    }

    /**
     * Handler on add of player
     * @param {*} player 
     */
    const onAddPlayer = (player) => {

        function isISODateString(str) {
            const isoDateRegex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(\.\d+)?(Z|([+-])(\d{2}):(\d{2}))?$/;
            if (!isoDateRegex.test(str)) {
                return false;
            }
            const date = new Date(str);
            return date.toISOString() === str;
        }

        try {
            const isoDate = isISODateString(player.birthDate) ? playerConverter.getFormattedFormBirthDate(player.birthDate) : (typeof player.birthDate === String ? player.birthDate : playerConverter.getFormattedFormBirthDate(player.birthDate.toISOString()));
            player = {...player, birthDate: isoDate};
            playerValidator(player.firstname, player.name, player.email, player.phone, player.birthDate);
            togglePlayerForm();
            props.onAddPlayer(player);
        }
        catch(e) {
            setError(e.message);
        }
        
    }

    return (
        
        props.players && props.onDeletePlayer && props.onAddPlayer ?

        <W>
            <Popup content={[error]} />
            {editPlayerForm ? <EditPlayerForm onAddPlayer={onAddPlayer} hideForm={togglePlayerForm} player={player ? player : null} /> : ""}
            {editIllustrativePlayerForm ? <EditIllustrativePlayerForm onAddIllustrativePlayer={onAddIllustrativePlayer} hideForm={toggleIllustrativePlayerForm} illustrativePlayer={illustrativePlayer ? illustrativePlayer : null} /> : ""}
            <StyledDiv>
                { props.players.map((player) => (
                    <PlayerBlock key={"p" + player.playerId} player={player} onDeletePlayer={props.onDeletePlayer} onClick={updateSelectedPlayer} />
                )) }
                { props.illustrativePlayers.map((ip) => (
                    <IllustrativePlayerBlock key={"ip" + ip.index} illustrativePlayer={ip} onDeleteIllustrativePlayer={props.onDeleteIllustrativePlayer} onClick={updateSelectedIllustrativePlayer} />
                )) }
            </StyledDiv>
            <ButtonsArea>
                <StyledBtn className="btn" onClick={togglePlayerForm}><ion-icon name="person-circle"></ion-icon><p>Ajouter un joueur Neoscore</p></StyledBtn>
                <StyledBtn className="btn" onClick={toggleIllustrativePlayerForm}><ion-icon name="add-circle"></ion-icon><p>Nom et prénom uniquement</p></StyledBtn>
            </ButtonsArea>
        </W>

        : null
        
    );

}

export default PlayerList;