import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

const Logout = () => {

    const [, setCookies] = useCookies(['proAuthorization']);
    const navigate = useNavigate();

    /**
     * Delete cookie
     */
    useEffect(() => {
        setCookies("proAuthorization", null);
        navigate('/login');
    })

    return <></>

}

export default Logout;