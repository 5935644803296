import { useState } from "react"
import styled from "styled-components"
import ProfileLinks from "./ProfileLinks/ProfileLinks";
import API_HOST_URL from "../../../../../../utils/apiHost";

/**
 * Styles
 */

const PP = styled.div `

    width: 60px;
    height: 60px;
    background: url("${props => `${API_HOST_URL}/pro/getlogo?proId=${props.pro.proId}`}");
    background-size: cover;
    border-radius: 100px;
    margin-right: 30px;
    cursor: pointer;

`;



/**
 * Component
 * @returns 
 */
const Profile = (props) => {

    const [showProfilePanel, setShowProfilePanel] = useState(false);

    const togglePanel = () => {
        setShowProfilePanel(showProfilePanel === true ? false : true);
    }

    return  (

        props.pro && props.subscription &&

        <>
            <PP onClick={togglePanel} pro={props.pro} />
            { showProfilePanel ? <ProfileLinks pro={props.pro} subscription={props.subscription} /> : null }
        </>

    )
}

export default Profile;