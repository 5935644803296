import { useState } from "react"
import styled from "styled-components"

const ButtonArea = styled.div `

    height:38px;
    width:38px;
    display:flex;
    justify-content:center;
    align-items:center;
    margin:0 5px;
    border-radius:8px;
    color:white;

    &.info {
        background:#3498db;
    }

    &.info:hover {
        background:#2980b9;
    }

    &.success {
        background:#2ecc71;
    }

    &.success:hover {
        background:#27ae60;
    }

    &.error {
        background:#e74c3c;
    }

    &.error:hover {
        background:#c0392b;
    }

    &:hover {
        cursor:pointer;
        transition:all .3s ease;
    }

    &.large {
        width:auto;
    }

    &.clicked, &.clicked:hover {

        background:grey;
        cursor:wait;

    }


`

const TextualDescription = styled.span `

    color:white;
    margin:0 8px;

`

const Button = (props) => {

    const [isClicked, setIsClicked] = useState(false);

    const onClick = (e) => {
        if(isClicked) return;
        if(!props.noloader) setIsClicked(true);
        props.onClick(e);
    }

    return (

        <ButtonArea className={props.type + (isClicked ? " clicked" : "")} onClick={onClick}>
            {props.icon && <ion-icon name={props.icon}></ion-icon> }
            { props.text && <TextualDescription> {props.text} </TextualDescription>}
        </ButtonArea>

    )

}

export default Button;